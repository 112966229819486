"use strict";

var cov_2bbwke2xjz = function () {
    var path = "/home/lobo/repos/taskatone-app/src/app/components/inputDuration/inputDuration.js",
        hash = "ab2f491382be43ff14096600dc9ba85324c3327f",
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/taskatone-app/src/app/components/inputDuration/inputDuration.js",
        statementMap: {
            "0": {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 11
                }
            },
            "1": {
                start: {
                    line: 4,
                    column: 12
                },
                end: {
                    line: 4,
                    column: 33
                }
            },
            "2": {
                start: {
                    line: 12,
                    column: 22
                },
                end: {
                    line: 12,
                    column: 39
                }
            },
            "3": {
                start: {
                    line: 13,
                    column: 20
                },
                end: {
                    line: 13,
                    column: 46
                }
            },
            "4": {
                start: {
                    line: 14,
                    column: 22
                },
                end: {
                    line: 14,
                    column: 65
                }
            },
            "5": {
                start: {
                    line: 15,
                    column: 22
                },
                end: {
                    line: 15,
                    column: 63
                }
            },
            "6": {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 46
                }
            },
            "7": {
                start: {
                    line: 17,
                    column: 25
                },
                end: {
                    line: 17,
                    column: 45
                }
            },
            "8": {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 52
                }
            },
            "9": {
                start: {
                    line: 18,
                    column: 27
                },
                end: {
                    line: 18,
                    column: 51
                }
            },
            "10": {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 52
                }
            },
            "11": {
                start: {
                    line: 19,
                    column: 27
                },
                end: {
                    line: 19,
                    column: 51
                }
            },
            "12": {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 49
                }
            },
            "13": {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 39
                }
            },
            "14": {
                start: {
                    line: 25,
                    column: 32
                },
                end: {
                    line: 25,
                    column: 39
                }
            },
            "15": {
                start: {
                    line: 26,
                    column: 20
                },
                end: {
                    line: 26,
                    column: 48
                }
            },
            "16": {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 9
                }
            },
            "17": {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 66
                }
            },
            "18": {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 49
                }
            },
            "19": {
                start: {
                    line: 34,
                    column: 0
                },
                end: {
                    line: 43,
                    column: 3
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 25
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 2
            },
            "1": {
                name: "(anonymous_1)",
                decl: {
                    start: {
                        line: 3,
                        column: 39
                    },
                    end: {
                        line: 3,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 45
                    },
                    end: {
                        line: 5,
                        column: 9
                    }
                },
                line: 3
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 15
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 8
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 21
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 11
            },
            "4": {
                name: "(anonymous_4)",
                decl: {
                    start: {
                        line: 24,
                        column: 4
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 22
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 24
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 12,
                        column: 22
                    },
                    end: {
                        line: 12,
                        column: 39
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 12,
                        column: 22
                    },
                    end: {
                        line: 12,
                        column: 34
                    }
                }, {
                    start: {
                        line: 12,
                        column: 38
                    },
                    end: {
                        line: 12,
                        column: 39
                    }
                }],
                line: 12
            },
            "1": {
                loc: {
                    start: {
                        line: 17,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 46
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 17,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 46
                    }
                }, {
                    start: {
                        line: 17,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 46
                    }
                }],
                line: 17
            },
            "2": {
                loc: {
                    start: {
                        line: 18,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 52
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 18,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 52
                    }
                }, {
                    start: {
                        line: 18,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 52
                    }
                }],
                line: 18
            },
            "3": {
                loc: {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 19,
                        column: 52
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 19,
                        column: 52
                    }
                }, {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 19,
                        column: 52
                    }
                }],
                line: 19
            },
            "4": {
                loc: {
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 25,
                        column: 39
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 25,
                        column: 39
                    }
                }, {
                    start: {
                        line: 25,
                        column: 8
                    },
                    end: {
                        line: 25,
                        column: 39
                    }
                }],
                line: 25
            },
            "5": {
                loc: {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                }, {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                }],
                line: 27
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0
        },
        b: {
            "0": [0, 0],
            "1": [0, 0],
            "2": [0, 0],
            "3": [0, 0],
            "4": [0, 0],
            "5": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var InputDurationController = function () {
    function InputDurationController($scope) {
        var _this = this;

        _classCallCheck(this, InputDurationController);

        cov_2bbwke2xjz.f[0]++;
        cov_2bbwke2xjz.s[0]++;

        $scope.$watch('$ctrl.ngModel', function () {
            cov_2bbwke2xjz.f[1]++;
            cov_2bbwke2xjz.s[1]++;

            _this.parseDuration();
        });
    }

    _createClass(InputDurationController, [{
        key: "$onInit",
        value: function $onInit() {
            cov_2bbwke2xjz.f[2]++;
        }
    }, {
        key: "parseDuration",
        value: function parseDuration() {
            cov_2bbwke2xjz.f[3]++;

            var sec_num = (cov_2bbwke2xjz.s[2]++, (cov_2bbwke2xjz.b[0][0]++, this.ngModel) || (cov_2bbwke2xjz.b[0][1]++, 0)); // don't forget the second param
            var hours = (cov_2bbwke2xjz.s[3]++, Math.floor(sec_num / 3600));
            var minutes = (cov_2bbwke2xjz.s[4]++, Math.floor((sec_num - hours * 3600) / 60));
            var seconds = (cov_2bbwke2xjz.s[5]++, sec_num - hours * 3600 - minutes * 60);

            cov_2bbwke2xjz.s[6]++;
            if (hours < 10) {
                cov_2bbwke2xjz.b[1][0]++;
                cov_2bbwke2xjz.s[7]++;
                hours = "0" + hours;
            } else {
                cov_2bbwke2xjz.b[1][1]++;
            }
            cov_2bbwke2xjz.s[8]++;
            if (minutes < 10) {
                cov_2bbwke2xjz.b[2][0]++;
                cov_2bbwke2xjz.s[9]++;
                minutes = "0" + minutes;
            } else {
                cov_2bbwke2xjz.b[2][1]++;
            }
            cov_2bbwke2xjz.s[10]++;
            if (seconds < 10) {
                cov_2bbwke2xjz.b[3][0]++;
                cov_2bbwke2xjz.s[11]++;
                seconds = "0" + seconds;
            } else {
                cov_2bbwke2xjz.b[3][1]++;
            }

            cov_2bbwke2xjz.s[12]++;
            this.viewDuration = hours + ':' + minutes;
        }
    }, {
        key: "changeDuration",
        value: function changeDuration() {
            cov_2bbwke2xjz.f[4]++;
            cov_2bbwke2xjz.s[13]++;

            if (!this.viewDuration) {
                    cov_2bbwke2xjz.b[4][0]++;
                    cov_2bbwke2xjz.s[14]++;
                    return;
                } else {
                cov_2bbwke2xjz.b[4][1]++;
            }var times = (cov_2bbwke2xjz.s[15]++, this.viewDuration.split(':'));
            cov_2bbwke2xjz.s[16]++;
            if (times.length == 2) {
                cov_2bbwke2xjz.b[5][0]++;
                cov_2bbwke2xjz.s[17]++;

                this.ngModel = times[1] * 60 + times[0] * 60 * 60;
                cov_2bbwke2xjz.s[18]++;
                this.ngChange({ value: this.ngModel });
            } else {
                cov_2bbwke2xjz.b[5][1]++;
            }
        }
    }]);

    return InputDurationController;
}();

cov_2bbwke2xjz.s[19]++;


angular.module('app').component('inputDuration', {
    templateUrl: 'app/components/inputDuration/inputDuration.html',
    controller: InputDurationController,
    bindings: {
        ngModel: '=',
        ngChange: '&'
    }
});