'use strict';

var cov_2cuu6w70ki = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/projectSelector/projectSelector.js',
        hash = '073c488b564037d9cb382dd77949919987d50264',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/projectSelector/projectSelector.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 9
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 12
                },
                end: {
                    line: 4,
                    column: 32
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 0
                },
                end: {
                    line: 19,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 15
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 2
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 3,
                        column: 8
                    },
                    end: {
                        line: 5,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 3,
                        column: 8
                    },
                    end: {
                        line: 5,
                        column: 9
                    }
                }, {
                    start: {
                        line: 3,
                        column: 8
                    },
                    end: {
                        line: 5,
                        column: 9
                    }
                }],
                line: 3
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProjectSelectorController = function () {
    function ProjectSelectorController() {
        _classCallCheck(this, ProjectSelectorController);
    }

    _createClass(ProjectSelectorController, [{
        key: 'changed',
        value: function changed() {
            cov_2cuu6w70ki.f[0]++;
            cov_2cuu6w70ki.s[0]++;

            if (this.ngModel == 'null') {
                cov_2cuu6w70ki.b[0][0]++;
                cov_2cuu6w70ki.s[1]++;

                this.ngModel = null;
            } else {
                cov_2cuu6w70ki.b[0][1]++;
            }
            cov_2cuu6w70ki.s[2]++;
            this.ngChange({ $project: this.ngModel });
        }
    }]);

    return ProjectSelectorController;
}();

cov_2cuu6w70ki.s[3]++;


angular.module('app').component('projectSelector', {
    templateUrl: 'app/components/projectSelector/projectSelector.html',
    controller: ProjectSelectorController,
    bindings: {
        ngChange: '&',
        ngModel: '='
    }
});