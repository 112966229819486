'use strict';

var cov_22wcmjkpzy = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/projectDetails/projectDetails.js',
        hash = 'b839089f946b3f705441a8a15c4f3d617557d8fd',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/projectDetails/projectDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 44
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 31
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 34
                }
            },
            '5': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 36
                }
            },
            '6': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 29
                }
            },
            '7': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 24
                }
            },
            '8': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 29
                }
            },
            '9': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 55
                }
            },
            '10': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 41
                }
            },
            '11': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 73
                }
            },
            '12': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 89
                }
            },
            '13': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 40
                }
            },
            '14': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 38
                }
            },
            '15': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 66
                }
            },
            '16': {
                start: {
                    line: 21,
                    column: 45
                },
                end: {
                    line: 21,
                    column: 64
                }
            },
            '17': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 11
                }
            },
            '18': {
                start: {
                    line: 23,
                    column: 12
                },
                end: {
                    line: 23,
                    column: 53
                }
            },
            '19': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 9
                }
            },
            '20': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 55
                }
            },
            '21': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 30,
                    column: 37
                }
            },
            '22': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 36
                }
            },
            '23': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 41
                }
            },
            '24': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 43
                }
            },
            '25': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 61
                }
            },
            '26': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 39
                }
            },
            '27': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 66
                }
            },
            '28': {
                start: {
                    line: 40,
                    column: 45
                },
                end: {
                    line: 40,
                    column: 64
                }
            },
            '29': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 24
                }
            },
            '30': {
                start: {
                    line: 45,
                    column: 22
                },
                end: {
                    line: 45,
                    column: 95
                }
            },
            '31': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 11
                }
            },
            '32': {
                start: {
                    line: 47,
                    column: 33
                },
                end: {
                    line: 47,
                    column: 103
                }
            },
            '33': {
                start: {
                    line: 48,
                    column: 27
                },
                end: {
                    line: 48,
                    column: 74
                }
            },
            '34': {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 51,
                    column: 13
                }
            },
            '35': {
                start: {
                    line: 50,
                    column: 16
                },
                end: {
                    line: 50,
                    column: 51
                }
            },
            '36': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 29
                }
            },
            '37': {
                start: {
                    line: 57,
                    column: 22
                },
                end: {
                    line: 57,
                    column: 99
                }
            },
            '38': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 11
                }
            },
            '39': {
                start: {
                    line: 59,
                    column: 33
                },
                end: {
                    line: 59,
                    column: 103
                }
            },
            '40': {
                start: {
                    line: 60,
                    column: 27
                },
                end: {
                    line: 60,
                    column: 78
                }
            },
            '41': {
                start: {
                    line: 61,
                    column: 12
                },
                end: {
                    line: 63,
                    column: 13
                }
            },
            '42': {
                start: {
                    line: 62,
                    column: 16
                },
                end: {
                    line: 62,
                    column: 56
                }
            },
            '43': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 35
                }
            },
            '44': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 11
                }
            },
            '45': {
                start: {
                    line: 70,
                    column: 12
                },
                end: {
                    line: 70,
                    column: 37
                }
            },
            '46': {
                start: {
                    line: 71,
                    column: 12
                },
                end: {
                    line: 71,
                    column: 36
                }
            },
            '47': {
                start: {
                    line: 72,
                    column: 12
                },
                end: {
                    line: 72,
                    column: 41
                }
            },
            '48': {
                start: {
                    line: 73,
                    column: 12
                },
                end: {
                    line: 73,
                    column: 43
                }
            },
            '49': {
                start: {
                    line: 78,
                    column: 23
                },
                end: {
                    line: 78,
                    column: 99
                }
            },
            '50': {
                start: {
                    line: 79,
                    column: 8
                },
                end: {
                    line: 80,
                    column: 26
                }
            },
            '51': {
                start: {
                    line: 79,
                    column: 33
                },
                end: {
                    line: 79,
                    column: 45
                }
            },
            '52': {
                start: {
                    line: 80,
                    column: 13
                },
                end: {
                    line: 80,
                    column: 26
                }
            },
            '53': {
                start: {
                    line: 84,
                    column: 23
                },
                end: {
                    line: 84,
                    column: 99
                }
            },
            '54': {
                start: {
                    line: 85,
                    column: 22
                },
                end: {
                    line: 85,
                    column: 23
                }
            },
            '55': {
                start: {
                    line: 86,
                    column: 21
                },
                end: {
                    line: 86,
                    column: 26
                }
            },
            '56': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 92,
                    column: 11
                }
            },
            '57': {
                start: {
                    line: 88,
                    column: 12
                },
                end: {
                    line: 90,
                    column: 13
                }
            },
            '58': {
                start: {
                    line: 89,
                    column: 16
                },
                end: {
                    line: 89,
                    column: 30
                }
            },
            '59': {
                start: {
                    line: 91,
                    column: 12
                },
                end: {
                    line: 91,
                    column: 25
                }
            },
            '60': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 22
                }
            },
            '61': {
                start: {
                    line: 97,
                    column: 18
                },
                end: {
                    line: 100,
                    column: 9
                }
            },
            '62': {
                start: {
                    line: 101,
                    column: 8
                },
                end: {
                    line: 103,
                    column: 9
                }
            },
            '63': {
                start: {
                    line: 102,
                    column: 12
                },
                end: {
                    line: 102,
                    column: 31
                }
            },
            '64': {
                start: {
                    line: 105,
                    column: 8
                },
                end: {
                    line: 105,
                    column: 92
                }
            },
            '65': {
                start: {
                    line: 105,
                    column: 72
                },
                end: {
                    line: 105,
                    column: 90
                }
            },
            '66': {
                start: {
                    line: 106,
                    column: 8
                },
                end: {
                    line: 106,
                    column: 30
                }
            },
            '67': {
                start: {
                    line: 110,
                    column: 18
                },
                end: {
                    line: 114,
                    column: 9
                }
            },
            '68': {
                start: {
                    line: 115,
                    column: 8
                },
                end: {
                    line: 115,
                    column: 64
                }
            },
            '69': {
                start: {
                    line: 116,
                    column: 8
                },
                end: {
                    line: 116,
                    column: 34
                }
            },
            '70': {
                start: {
                    line: 117,
                    column: 8
                },
                end: {
                    line: 117,
                    column: 37
                }
            },
            '71': {
                start: {
                    line: 121,
                    column: 8
                },
                end: {
                    line: 124,
                    column: 11
                }
            },
            '72': {
                start: {
                    line: 122,
                    column: 12
                },
                end: {
                    line: 122,
                    column: 33
                }
            },
            '73': {
                start: {
                    line: 123,
                    column: 12
                },
                end: {
                    line: 123,
                    column: 47
                }
            },
            '74': {
                start: {
                    line: 128,
                    column: 8
                },
                end: {
                    line: 128,
                    column: 68
                }
            },
            '75': {
                start: {
                    line: 129,
                    column: 8
                },
                end: {
                    line: 131,
                    column: 11
                }
            },
            '76': {
                start: {
                    line: 130,
                    column: 12
                },
                end: {
                    line: 130,
                    column: 45
                }
            },
            '77': {
                start: {
                    line: 135,
                    column: 0
                },
                end: {
                    line: 159,
                    column: 7
                }
            },
            '78': {
                start: {
                    line: 145,
                    column: 8
                },
                end: {
                    line: 158,
                    column: 10
                }
            },
            '79': {
                start: {
                    line: 146,
                    column: 25
                },
                end: {
                    line: 146,
                    column: 27
                }
            },
            '80': {
                start: {
                    line: 147,
                    column: 12
                },
                end: {
                    line: 156,
                    column: 15
                }
            },
            '81': {
                start: {
                    line: 148,
                    column: 16
                },
                end: {
                    line: 155,
                    column: 17
                }
            },
            '82': {
                start: {
                    line: 149,
                    column: 20
                },
                end: {
                    line: 154,
                    column: 21
                }
            },
            '83': {
                start: {
                    line: 150,
                    column: 24
                },
                end: {
                    line: 150,
                    column: 42
                }
            },
            '84': {
                start: {
                    line: 152,
                    column: 25
                },
                end: {
                    line: 154,
                    column: 21
                }
            },
            '85': {
                start: {
                    line: 153,
                    column: 24
                },
                end: {
                    line: 153,
                    column: 42
                }
            },
            '86': {
                start: {
                    line: 157,
                    column: 12
                },
                end: {
                    line: 157,
                    column: 26
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 123
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 14
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                line: 17
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 21,
                        column: 34
                    },
                    end: {
                        line: 21,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 45
                    },
                    end: {
                        line: 21,
                        column: 64
                    }
                },
                line: 21
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 22,
                        column: 38
                    },
                    end: {
                        line: 22,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 44
                    },
                    end: {
                        line: 24,
                        column: 9
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 27,
                        column: 4
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 29
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 27
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 37,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 24
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 37
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 40,
                        column: 34
                    },
                    end: {
                        line: 40,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 45
                    },
                    end: {
                        line: 40,
                        column: 64
                    }
                },
                line: 40
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 23
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                line: 43
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 46,
                        column: 22
                    },
                    end: {
                        line: 46,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 44
                    },
                    end: {
                        line: 52,
                        column: 9
                    }
                },
                line: 46
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 55,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 28
                    },
                    end: {
                        line: 65,
                        column: 5
                    }
                },
                line: 55
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 58,
                        column: 22
                    },
                    end: {
                        line: 58,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 44
                    },
                    end: {
                        line: 64,
                        column: 9
                    }
                },
                line: 58
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 67,
                        column: 4
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 28
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                line: 67
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 69,
                        column: 47
                    },
                    end: {
                        line: 69,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 53
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                },
                line: 69
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 77,
                        column: 4
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 17
                    },
                    end: {
                        line: 81,
                        column: 5
                    }
                },
                line: 77
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 83,
                        column: 4
                    },
                    end: {
                        line: 83,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 16
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                line: 83
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 87,
                        column: 34
                    },
                    end: {
                        line: 87,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 87,
                        column: 45
                    },
                    end: {
                        line: 92,
                        column: 9
                    }
                },
                line: 87
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 96,
                        column: 4
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 96,
                        column: 27
                    },
                    end: {
                        line: 107,
                        column: 5
                    }
                },
                line: 96
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 105,
                        column: 62
                    },
                    end: {
                        line: 105,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 105,
                        column: 72
                    },
                    end: {
                        line: 105,
                        column: 90
                    }
                },
                line: 105
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 109,
                        column: 4
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 32
                    },
                    end: {
                        line: 118,
                        column: 5
                    }
                },
                line: 109
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 120,
                        column: 4
                    },
                    end: {
                        line: 120,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 120,
                        column: 21
                    },
                    end: {
                        line: 125,
                        column: 5
                    }
                },
                line: 120
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 121,
                        column: 39
                    },
                    end: {
                        line: 121,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 121,
                        column: 45
                    },
                    end: {
                        line: 124,
                        column: 9
                    }
                },
                line: 121
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 127,
                        column: 4
                    },
                    end: {
                        line: 127,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 127,
                        column: 35
                    },
                    end: {
                        line: 132,
                        column: 5
                    }
                },
                line: 127
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 129,
                        column: 57
                    },
                    end: {
                        line: 129,
                        column: 58
                    }
                },
                loc: {
                    start: {
                        line: 129,
                        column: 63
                    },
                    end: {
                        line: 131,
                        column: 9
                    }
                },
                line: 129
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 144,
                        column: 29
                    },
                    end: {
                        line: 144,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 53
                    },
                    end: {
                        line: 159,
                        column: 5
                    }
                },
                line: 144
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 145,
                        column: 15
                    },
                    end: {
                        line: 145,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 41
                    },
                    end: {
                        line: 158,
                        column: 9
                    }
                },
                line: 145
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 147,
                        column: 35
                    },
                    end: {
                        line: 147,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 147,
                        column: 51
                    },
                    end: {
                        line: 156,
                        column: 13
                    }
                },
                line: 147
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 28,
                        column: 8
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 28,
                        column: 8
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                }, {
                    start: {
                        line: 28,
                        column: 8
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                }],
                line: 28
            },
            '1': {
                loc: {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }, {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }],
                line: 49
            },
            '2': {
                loc: {
                    start: {
                        line: 49,
                        column: 16
                    },
                    end: {
                        line: 49,
                        column: 74
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 49,
                        column: 16
                    },
                    end: {
                        line: 49,
                        column: 38
                    }
                }, {
                    start: {
                        line: 49,
                        column: 42
                    },
                    end: {
                        line: 49,
                        column: 74
                    }
                }],
                line: 49
            },
            '3': {
                loc: {
                    start: {
                        line: 61,
                        column: 12
                    },
                    end: {
                        line: 63,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 61,
                        column: 12
                    },
                    end: {
                        line: 63,
                        column: 13
                    }
                }, {
                    start: {
                        line: 61,
                        column: 12
                    },
                    end: {
                        line: 63,
                        column: 13
                    }
                }],
                line: 61
            },
            '4': {
                loc: {
                    start: {
                        line: 61,
                        column: 16
                    },
                    end: {
                        line: 61,
                        column: 74
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 61,
                        column: 16
                    },
                    end: {
                        line: 61,
                        column: 38
                    }
                }, {
                    start: {
                        line: 61,
                        column: 42
                    },
                    end: {
                        line: 61,
                        column: 74
                    }
                }],
                line: 61
            },
            '5': {
                loc: {
                    start: {
                        line: 79,
                        column: 8
                    },
                    end: {
                        line: 80,
                        column: 26
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 79,
                        column: 8
                    },
                    end: {
                        line: 80,
                        column: 26
                    }
                }, {
                    start: {
                        line: 79,
                        column: 8
                    },
                    end: {
                        line: 80,
                        column: 26
                    }
                }],
                line: 79
            },
            '6': {
                loc: {
                    start: {
                        line: 88,
                        column: 12
                    },
                    end: {
                        line: 90,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 88,
                        column: 12
                    },
                    end: {
                        line: 90,
                        column: 13
                    }
                }, {
                    start: {
                        line: 88,
                        column: 12
                    },
                    end: {
                        line: 90,
                        column: 13
                    }
                }],
                line: 88
            },
            '7': {
                loc: {
                    start: {
                        line: 88,
                        column: 16
                    },
                    end: {
                        line: 88,
                        column: 74
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 88,
                        column: 16
                    },
                    end: {
                        line: 88,
                        column: 42
                    }
                }, {
                    start: {
                        line: 88,
                        column: 46
                    },
                    end: {
                        line: 88,
                        column: 74
                    }
                }],
                line: 88
            },
            '8': {
                loc: {
                    start: {
                        line: 101,
                        column: 8
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 101,
                        column: 8
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                }, {
                    start: {
                        line: 101,
                        column: 8
                    },
                    end: {
                        line: 103,
                        column: 9
                    }
                }],
                line: 101
            },
            '9': {
                loc: {
                    start: {
                        line: 101,
                        column: 12
                    },
                    end: {
                        line: 101,
                        column: 35
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 101,
                        column: 12
                    },
                    end: {
                        line: 101,
                        column: 16
                    }
                }, {
                    start: {
                        line: 101,
                        column: 20
                    },
                    end: {
                        line: 101,
                        column: 35
                    }
                }],
                line: 101
            },
            '10': {
                loc: {
                    start: {
                        line: 148,
                        column: 16
                    },
                    end: {
                        line: 155,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 148,
                        column: 16
                    },
                    end: {
                        line: 155,
                        column: 17
                    }
                }, {
                    start: {
                        line: 148,
                        column: 16
                    },
                    end: {
                        line: 155,
                        column: 17
                    }
                }],
                line: 148
            },
            '11': {
                loc: {
                    start: {
                        line: 149,
                        column: 20
                    },
                    end: {
                        line: 154,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 149,
                        column: 20
                    },
                    end: {
                        line: 154,
                        column: 21
                    }
                }, {
                    start: {
                        line: 149,
                        column: 20
                    },
                    end: {
                        line: 154,
                        column: 21
                    }
                }],
                line: 149
            },
            '12': {
                loc: {
                    start: {
                        line: 152,
                        column: 25
                    },
                    end: {
                        line: 154,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 152,
                        column: 25
                    },
                    end: {
                        line: 154,
                        column: 21
                    }
                }, {
                    start: {
                        line: 152,
                        column: 25
                    },
                    end: {
                        line: 154,
                        column: 21
                    }
                }],
                line: 152
            },
            '13': {
                loc: {
                    start: {
                        line: 152,
                        column: 29
                    },
                    end: {
                        line: 152,
                        column: 69
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 152,
                        column: 29
                    },
                    end: {
                        line: 152,
                        column: 44
                    }
                }, {
                    start: {
                        line: 152,
                        column: 48
                    },
                    end: {
                        line: 152,
                        column: 69
                    }
                }],
                line: 152
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0],
            '12': [0, 0],
            '13': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProjectDetailsController = function () {
    function ProjectDetailsController(UsersTable, ProjectReportsTable, NewProjectReportModalService, StoriesTable, $rootScope, $filter, $window) {
        _classCallCheck(this, ProjectDetailsController);

        cov_22wcmjkpzy.f[0]++;
        cov_22wcmjkpzy.s[0]++;

        this.users = UsersTable.$all;
        cov_22wcmjkpzy.s[1]++;
        this.newTaskUser = $rootScope.me.id;
        cov_22wcmjkpzy.s[2]++;
        this.$filter = $filter;
        cov_22wcmjkpzy.s[3]++;
        this.$window = $window;
        cov_22wcmjkpzy.s[4]++;
        this.currentNav = "tasks";
        cov_22wcmjkpzy.s[5]++;
        this.currentProposal = null;
        cov_22wcmjkpzy.s[6]++;
        this.proposal = null;
        cov_22wcmjkpzy.s[7]++;
        this.roles = [];
        cov_22wcmjkpzy.s[8]++;
        this.deliveries = [];
        cov_22wcmjkpzy.s[9]++;
        this.ProjectReportsTable = ProjectReportsTable;
        cov_22wcmjkpzy.s[10]++;
        this.StoriesTable = StoriesTable;
        cov_22wcmjkpzy.s[11]++;
        this.NewProjectReportModalService = NewProjectReportModalService;
    }

    _createClass(ProjectDetailsController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_22wcmjkpzy.f[1]++;
            cov_22wcmjkpzy.s[12]++;

            this.project.$load('tasks').$load('stories').$load('proposals').$load('reports');
            cov_22wcmjkpzy.s[13]++;
            this.project.$tasks.$limit = 25;
            cov_22wcmjkpzy.s[14]++;
            this.project.$tasks.$refresh();
            cov_22wcmjkpzy.s[15]++;
            this.project.$tasks.$then(function (tasks) {
                cov_22wcmjkpzy.f[2]++;
                cov_22wcmjkpzy.s[16]++;
                return tasks.$load('user');
            });
            cov_22wcmjkpzy.s[17]++;
            this.project.$proposals.$then(function () {
                cov_22wcmjkpzy.f[3]++;
                cov_22wcmjkpzy.s[18]++;

                _this.project.$proposals.$load('stories');
            });
        }
    }, {
        key: 'selectProposal',
        value: function selectProposal(proposal) {
            cov_22wcmjkpzy.f[4]++;
            cov_22wcmjkpzy.s[19]++;

            if (proposal !== null) {
                cov_22wcmjkpzy.b[0][0]++;
                cov_22wcmjkpzy.s[20]++;

                this.currentProposal = String(proposal.id);
                cov_22wcmjkpzy.s[21]++;
                this.proposal = proposal;
                cov_22wcmjkpzy.s[22]++;
                this.getProposalRoles();
                cov_22wcmjkpzy.s[23]++;
                this.getProposalDeliveries();
                cov_22wcmjkpzy.s[24]++;
                this.proposal.$load('stories');
            } else {
                cov_22wcmjkpzy.b[0][1]++;
            }
        }
    }, {
        key: 'filterTasks',
        value: function filterTasks(filter) {
            cov_22wcmjkpzy.f[5]++;
            cov_22wcmjkpzy.s[25]++;

            this.project.$tasks.$params.name__icontains = filter;
            cov_22wcmjkpzy.s[26]++;
            this.project.$tasks.$refresh();
            cov_22wcmjkpzy.s[27]++;
            this.project.$tasks.$then(function (tasks) {
                cov_22wcmjkpzy.f[6]++;
                cov_22wcmjkpzy.s[28]++;
                return tasks.$load('user');
            });
        }
    }, {
        key: 'getProposalRoles',
        value: function getProposalRoles() {
            var _this2 = this;

            cov_22wcmjkpzy.f[7]++;
            cov_22wcmjkpzy.s[29]++;

            this.roles = [];
            var stories = (cov_22wcmjkpzy.s[30]++, this.StoriesTable.$search({ proposal: this.proposal.id, ordering: 'role' }));
            cov_22wcmjkpzy.s[31]++;
            stories.$then(function (filtered_stories) {
                cov_22wcmjkpzy.f[8]++;

                var actual_stories = (cov_22wcmjkpzy.s[32]++, _this2.$filter('actualVersion')(filtered_stories, _this2.proposal.version));
                var filtered = (cov_22wcmjkpzy.s[33]++, _this2.$filter('groupBy')(actual_stories, 'role'));
                cov_22wcmjkpzy.s[34]++;
                if ((cov_22wcmjkpzy.b[2][0]++, filtered !== undefined) && (cov_22wcmjkpzy.b[2][1]++, Object.keys(filtered).length > 0)) {
                    cov_22wcmjkpzy.b[1][0]++;
                    cov_22wcmjkpzy.s[35]++;

                    _this2.roles = Object.keys(filtered);
                } else {
                    cov_22wcmjkpzy.b[1][1]++;
                }
            });
        }
    }, {
        key: 'getProposalDeliveries',
        value: function getProposalDeliveries() {
            var _this3 = this;

            cov_22wcmjkpzy.f[9]++;
            cov_22wcmjkpzy.s[36]++;

            this.deliveries = [];
            var stories = (cov_22wcmjkpzy.s[37]++, this.StoriesTable.$search({ proposal: this.proposal.id, ordering: 'delivery' }));
            cov_22wcmjkpzy.s[38]++;
            stories.$then(function (filtered_stories) {
                cov_22wcmjkpzy.f[10]++;

                var actual_stories = (cov_22wcmjkpzy.s[39]++, _this3.$filter('actualVersion')(filtered_stories, _this3.proposal.version));
                var filtered = (cov_22wcmjkpzy.s[40]++, _this3.$filter('groupBy')(actual_stories, 'delivery'));
                cov_22wcmjkpzy.s[41]++;
                if ((cov_22wcmjkpzy.b[4][0]++, filtered !== undefined) && (cov_22wcmjkpzy.b[4][1]++, Object.keys(filtered).length > 0)) {
                    cov_22wcmjkpzy.b[3][0]++;
                    cov_22wcmjkpzy.s[42]++;

                    _this3.deliveries = Object.keys(filtered);
                } else {
                    cov_22wcmjkpzy.b[3][1]++;
                }
            });
        }
    }, {
        key: 'updateProposalVersion',
        value: function updateProposalVersion() {
            var _this4 = this;

            cov_22wcmjkpzy.f[11]++;
            cov_22wcmjkpzy.s[43]++;

            this.proposal.version += 1;
            cov_22wcmjkpzy.s[44]++;
            this.proposal.$save(['version']).$then(function () {
                cov_22wcmjkpzy.f[12]++;
                cov_22wcmjkpzy.s[45]++;

                _this4.proposal.$refresh();
                cov_22wcmjkpzy.s[46]++;
                _this4.getProposalRoles();
                cov_22wcmjkpzy.s[47]++;
                _this4.getProposalDeliveries();
                cov_22wcmjkpzy.s[48]++;
                _this4.proposal.$load('stories');
            });
        }
    }, {
        key: 'notUpdated',
        value: function notUpdated() {
            cov_22wcmjkpzy.f[13]++;

            var filtered = (cov_22wcmjkpzy.s[49]++, this.$filter('actualVersion')(this.proposal.$stories, this.proposal.version));
            cov_22wcmjkpzy.s[50]++;
            if (filtered.length > 0) {
                    cov_22wcmjkpzy.b[5][0]++;
                    cov_22wcmjkpzy.s[51]++;
                    return true;
                } else {
                    cov_22wcmjkpzy.b[5][1]++;
                    cov_22wcmjkpzy.s[52]++;
                    return false;
                }
        }
    }, {
        key: 'questions',
        value: function questions() {
            cov_22wcmjkpzy.f[14]++;

            var filtered = (cov_22wcmjkpzy.s[53]++, this.$filter('actualVersion')(this.proposal.$stories, this.proposal.version));
            var counter = (cov_22wcmjkpzy.s[54]++, 0);
            var result = (cov_22wcmjkpzy.s[55]++, false);
            cov_22wcmjkpzy.s[56]++;
            angular.forEach(filtered, function (story) {
                cov_22wcmjkpzy.f[15]++;
                cov_22wcmjkpzy.s[57]++;

                if ((cov_22wcmjkpzy.b[7][0]++, story.description !== null) && (cov_22wcmjkpzy.b[7][1]++, story.description.length > 0)) {
                    cov_22wcmjkpzy.b[6][0]++;
                    cov_22wcmjkpzy.s[58]++;

                    result = true;
                } else {
                    cov_22wcmjkpzy.b[6][1]++;
                }
                cov_22wcmjkpzy.s[59]++;
                counter += 1;
            });
            cov_22wcmjkpzy.s[60]++;
            return result;
        }
    }, {
        key: 'createTask',
        value: function createTask(name, user) {
            cov_22wcmjkpzy.f[16]++;

            var obj = (cov_22wcmjkpzy.s[61]++, {
                name: name,
                project: this.project.id
            });
            cov_22wcmjkpzy.s[62]++;
            if ((cov_22wcmjkpzy.b[9][0]++, user) && (cov_22wcmjkpzy.b[9][1]++, user !== 'None')) {
                cov_22wcmjkpzy.b[8][0]++;
                cov_22wcmjkpzy.s[63]++;

                obj['user'] = user;
            } else {
                cov_22wcmjkpzy.b[8][1]++;
            }

            cov_22wcmjkpzy.s[64]++;
            this.newTask = this.project.$tasks.$create(obj).$then(function (task) {
                cov_22wcmjkpzy.f[17]++;
                cov_22wcmjkpzy.s[65]++;
                return task.$load('user');
            });
            cov_22wcmjkpzy.s[66]++;
            this.newTaskName = '';
        }
    }, {
        key: 'createProposal',
        value: function createProposal(name, order) {
            cov_22wcmjkpzy.f[18]++;

            var obj = (cov_22wcmjkpzy.s[67]++, {
                project: this.project.id,
                name: name,
                order: order
            });
            cov_22wcmjkpzy.s[68]++;
            this.newProposal = this.project.$proposals.$create(obj);
            cov_22wcmjkpzy.s[69]++;
            this.newProposalName = '';
            cov_22wcmjkpzy.s[70]++;
            this.newProposalOrder = null;
        }
    }, {
        key: 'removeProposal',
        value: function removeProposal() {
            var _this5 = this;

            cov_22wcmjkpzy.f[19]++;
            cov_22wcmjkpzy.s[71]++;

            this.proposal.$destroy().$then(function () {
                cov_22wcmjkpzy.f[20]++;
                cov_22wcmjkpzy.s[72]++;

                _this5.proposal = null;
                cov_22wcmjkpzy.s[73]++;
                _this5.project.$proposals.$refresh();
            });
        }
    }, {
        key: 'displayNewProjectReportModal',
        value: function displayNewProjectReportModal() {
            var _this6 = this;

            cov_22wcmjkpzy.f[21]++;
            cov_22wcmjkpzy.s[74]++;

            this.NewProjectReportModalService.project = this.project.id;
            cov_22wcmjkpzy.s[75]++;
            this.NewProjectReportModalService.display().then(function () {
                cov_22wcmjkpzy.f[22]++;
                cov_22wcmjkpzy.s[76]++;

                _this6.project.$reports.$refresh();
            });
        }
    }]);

    return ProjectDetailsController;
}();

cov_22wcmjkpzy.s[77]++;


angular.module('app').component('projectDetails', {
    templateUrl: 'app/components/projectDetails/projectDetails.html',
    controller: ProjectDetailsController,
    bindings: {
        project: '<'
    }
}).filter('actualVersion', function (StoriesTable) {
    cov_22wcmjkpzy.f[23]++;
    cov_22wcmjkpzy.s[78]++;

    return function (items, version) {
        cov_22wcmjkpzy.f[24]++;

        var result = (cov_22wcmjkpzy.s[79]++, []);
        cov_22wcmjkpzy.s[80]++;
        angular.forEach(items, function (item) {
            cov_22wcmjkpzy.f[25]++;
            cov_22wcmjkpzy.s[81]++;

            if (item.version !== null) {
                cov_22wcmjkpzy.b[10][0]++;
                cov_22wcmjkpzy.s[82]++;

                if (item.version === version) {
                    cov_22wcmjkpzy.b[11][0]++;
                    cov_22wcmjkpzy.s[83]++;

                    result.push(item);
                } else {
                        cov_22wcmjkpzy.b[11][1]++;
                        cov_22wcmjkpzy.s[84]++;
                        if ((cov_22wcmjkpzy.b[13][0]++, !item.isDeleted) && (cov_22wcmjkpzy.b[13][1]++, item.duplicates === 0)) {
                            cov_22wcmjkpzy.b[12][0]++;
                            cov_22wcmjkpzy.s[85]++;

                            result.push(item);
                        } else {
                            cov_22wcmjkpzy.b[12][1]++;
                        }
                    }
            } else {
                cov_22wcmjkpzy.b[10][1]++;
            }
        });
        cov_22wcmjkpzy.s[86]++;
        return result;
    };
});