'use strict';

var cov_2aif0omwvg = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/projectStatistic/projectStatistic.js',
        hash = '65c854658b8a4917ed6fafe4f651cc12d8a3b29b',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/projectStatistic/projectStatistic.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2aif0omwvg.s[0]++;
angular.module('app').component('projectStatistic', {
    templateUrl: 'app/components/projectStatistic/projectStatistic.html',
    bindings: {
        statistic: '<'
    }
});