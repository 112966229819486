'use strict';

var cov_11jt39av3r = function () {
    var path = '/home/lobo/repos/taskatone-app/src/routes.js',
        hash = '3a01ebc7480b51f865706eb8525f4345027884b5',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 154,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 27,
                    column: 50
                },
                end: {
                    line: 27,
                    column: 115
                }
            },
            '5': {
                start: {
                    line: 27,
                    column: 101
                },
                end: {
                    line: 27,
                    column: 105
                }
            },
            '6': {
                start: {
                    line: 58,
                    column: 41
                },
                end: {
                    line: 60,
                    column: 55
                }
            },
            '7': {
                start: {
                    line: 60,
                    column: 38
                },
                end: {
                    line: 60,
                    column: 45
                }
            },
            '8': {
                start: {
                    line: 61,
                    column: 45
                },
                end: {
                    line: 63,
                    column: 63
                }
            },
            '9': {
                start: {
                    line: 63,
                    column: 42
                },
                end: {
                    line: 63,
                    column: 53
                }
            },
            '10': {
                start: {
                    line: 70,
                    column: 45
                },
                end: {
                    line: 70,
                    column: 107
                }
            },
            '11': {
                start: {
                    line: 70,
                    column: 89
                },
                end: {
                    line: 70,
                    column: 97
                }
            },
            '12': {
                start: {
                    line: 77,
                    column: 58
                },
                end: {
                    line: 77,
                    column: 131
                }
            },
            '13': {
                start: {
                    line: 77,
                    column: 114
                },
                end: {
                    line: 77,
                    column: 121
                }
            },
            '14': {
                start: {
                    line: 84,
                    column: 46
                },
                end: {
                    line: 84,
                    column: 111
                }
            },
            '15': {
                start: {
                    line: 84,
                    column: 91
                },
                end: {
                    line: 84,
                    column: 101
                }
            },
            '16': {
                start: {
                    line: 91,
                    column: 61
                },
                end: {
                    line: 91,
                    column: 137
                }
            },
            '17': {
                start: {
                    line: 91,
                    column: 118
                },
                end: {
                    line: 91,
                    column: 127
                }
            },
            '18': {
                start: {
                    line: 99,
                    column: 49
                },
                end: {
                    line: 99,
                    column: 117
                }
            },
            '19': {
                start: {
                    line: 99,
                    column: 97
                },
                end: {
                    line: 99,
                    column: 107
                }
            },
            '20': {
                start: {
                    line: 106,
                    column: 63
                },
                end: {
                    line: 106,
                    column: 164
                }
            },
            '21': {
                start: {
                    line: 106,
                    column: 144
                },
                end: {
                    line: 106,
                    column: 154
                }
            },
            '22': {
                start: {
                    line: 119,
                    column: 52
                },
                end: {
                    line: 119,
                    column: 116
                }
            },
            '23': {
                start: {
                    line: 119,
                    column: 102
                },
                end: {
                    line: 119,
                    column: 106
                }
            },
            '24': {
                start: {
                    line: 126,
                    column: 52
                },
                end: {
                    line: 126,
                    column: 116
                }
            },
            '25': {
                start: {
                    line: 126,
                    column: 102
                },
                end: {
                    line: 126,
                    column: 106
                }
            },
            '26': {
                start: {
                    line: 133,
                    column: 40
                },
                end: {
                    line: 133,
                    column: 86
                }
            },
            '27': {
                start: {
                    line: 134,
                    column: 49
                },
                end: {
                    line: 137,
                    column: 55
                }
            },
            '28': {
                start: {
                    line: 137,
                    column: 38
                },
                end: {
                    line: 137,
                    column: 45
                }
            },
            '29': {
                start: {
                    line: 144,
                    column: 52
                },
                end: {
                    line: 144,
                    column: 116
                }
            },
            '30': {
                start: {
                    line: 144,
                    column: 102
                },
                end: {
                    line: 144,
                    column: 106
                }
            },
            '31': {
                start: {
                    line: 151,
                    column: 58
                },
                end: {
                    line: 151,
                    column: 131
                }
            },
            '32': {
                start: {
                    line: 151,
                    column: 114
                },
                end: {
                    line: 151,
                    column: 121
                }
            },
            '33': {
                start: {
                    line: 152,
                    column: 60
                },
                end: {
                    line: 152,
                    column: 142
                }
            },
            '34': {
                start: {
                    line: 152,
                    column: 124
                },
                end: {
                    line: 152,
                    column: 132
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 77
                    },
                    end: {
                        line: 157,
                        column: 1
                    }
                },
                line: 6
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 27,
                        column: 22
                    },
                    end: {
                        line: 27,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 50
                    },
                    end: {
                        line: 27,
                        column: 115
                    }
                },
                line: 27
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 27,
                        column: 91
                    },
                    end: {
                        line: 27,
                        column: 92
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 101
                    },
                    end: {
                        line: 27,
                        column: 105
                    }
                },
                line: 27
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 58,
                        column: 25
                    },
                    end: {
                        line: 58,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 41
                    },
                    end: {
                        line: 60,
                        column: 55
                    }
                },
                line: 58
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 60,
                        column: 25
                    },
                    end: {
                        line: 60,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 38
                    },
                    end: {
                        line: 60,
                        column: 45
                    }
                },
                line: 60
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 61,
                        column: 29
                    },
                    end: {
                        line: 61,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 45
                    },
                    end: {
                        line: 63,
                        column: 63
                    }
                },
                line: 61
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 63,
                        column: 25
                    },
                    end: {
                        line: 63,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 63,
                        column: 42
                    },
                    end: {
                        line: 63,
                        column: 53
                    }
                },
                line: 63
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 70,
                        column: 26
                    },
                    end: {
                        line: 70,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 45
                    },
                    end: {
                        line: 70,
                        column: 107
                    }
                },
                line: 70
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 70,
                        column: 75
                    },
                    end: {
                        line: 70,
                        column: 76
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 89
                    },
                    end: {
                        line: 70,
                        column: 97
                    }
                },
                line: 70
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 77,
                        column: 25
                    },
                    end: {
                        line: 77,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 58
                    },
                    end: {
                        line: 77,
                        column: 131
                    }
                },
                line: 77
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 77,
                        column: 101
                    },
                    end: {
                        line: 77,
                        column: 102
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 114
                    },
                    end: {
                        line: 77,
                        column: 121
                    }
                },
                line: 77
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 84,
                        column: 28
                    },
                    end: {
                        line: 84,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 46
                    },
                    end: {
                        line: 84,
                        column: 111
                    }
                },
                line: 84
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 84,
                        column: 77
                    },
                    end: {
                        line: 84,
                        column: 78
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 91
                    },
                    end: {
                        line: 84,
                        column: 101
                    }
                },
                line: 84
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 91,
                        column: 27
                    },
                    end: {
                        line: 91,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 91,
                        column: 61
                    },
                    end: {
                        line: 91,
                        column: 137
                    }
                },
                line: 91
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 91,
                        column: 105
                    },
                    end: {
                        line: 91,
                        column: 106
                    }
                },
                loc: {
                    start: {
                        line: 91,
                        column: 118
                    },
                    end: {
                        line: 91,
                        column: 127
                    }
                },
                line: 91
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 99,
                        column: 28
                    },
                    end: {
                        line: 99,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 99,
                        column: 49
                    },
                    end: {
                        line: 99,
                        column: 117
                    }
                },
                line: 99
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 99,
                        column: 81
                    },
                    end: {
                        line: 99,
                        column: 82
                    }
                },
                loc: {
                    start: {
                        line: 99,
                        column: 97
                    },
                    end: {
                        line: 99,
                        column: 107
                    }
                },
                line: 99
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 106,
                        column: 28
                    },
                    end: {
                        line: 106,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 106,
                        column: 63
                    },
                    end: {
                        line: 106,
                        column: 164
                    }
                },
                line: 106
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 106,
                        column: 128
                    },
                    end: {
                        line: 106,
                        column: 129
                    }
                },
                loc: {
                    start: {
                        line: 106,
                        column: 144
                    },
                    end: {
                        line: 106,
                        column: 154
                    }
                },
                line: 106
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 119,
                        column: 22
                    },
                    end: {
                        line: 119,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 119,
                        column: 52
                    },
                    end: {
                        line: 119,
                        column: 116
                    }
                },
                line: 119
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 119,
                        column: 92
                    },
                    end: {
                        line: 119,
                        column: 93
                    }
                },
                loc: {
                    start: {
                        line: 119,
                        column: 102
                    },
                    end: {
                        line: 119,
                        column: 106
                    }
                },
                line: 119
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 126,
                        column: 22
                    },
                    end: {
                        line: 126,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 126,
                        column: 52
                    },
                    end: {
                        line: 126,
                        column: 116
                    }
                },
                line: 126
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 126,
                        column: 92
                    },
                    end: {
                        line: 126,
                        column: 93
                    }
                },
                loc: {
                    start: {
                        line: 126,
                        column: 102
                    },
                    end: {
                        line: 126,
                        column: 106
                    }
                },
                line: 126
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 133,
                        column: 22
                    },
                    end: {
                        line: 133,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 133,
                        column: 40
                    },
                    end: {
                        line: 133,
                        column: 86
                    }
                },
                line: 133
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 134,
                        column: 25
                    },
                    end: {
                        line: 134,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 134,
                        column: 49
                    },
                    end: {
                        line: 137,
                        column: 55
                    }
                },
                line: 134
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 137,
                        column: 25
                    },
                    end: {
                        line: 137,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 38
                    },
                    end: {
                        line: 137,
                        column: 45
                    }
                },
                line: 137
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 144,
                        column: 22
                    },
                    end: {
                        line: 144,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 52
                    },
                    end: {
                        line: 144,
                        column: 116
                    }
                },
                line: 144
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 144,
                        column: 92
                    },
                    end: {
                        line: 144,
                        column: 93
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 102
                    },
                    end: {
                        line: 144,
                        column: 106
                    }
                },
                line: 144
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 151,
                        column: 25
                    },
                    end: {
                        line: 151,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 151,
                        column: 58
                    },
                    end: {
                        line: 151,
                        column: 131
                    }
                },
                line: 151
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 151,
                        column: 101
                    },
                    end: {
                        line: 151,
                        column: 102
                    }
                },
                loc: {
                    start: {
                        line: 151,
                        column: 114
                    },
                    end: {
                        line: 151,
                        column: 121
                    }
                },
                line: 151
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 152,
                        column: 26
                    },
                    end: {
                        line: 152,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 60
                    },
                    end: {
                        line: 152,
                        column: 142
                    }
                },
                line: 152
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 152,
                        column: 110
                    },
                    end: {
                        line: 152,
                        column: 111
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 124
                    },
                    end: {
                        line: 152,
                        column: 132
                    }
                },
                line: 152
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 133,
                        column: 40
                    },
                    end: {
                        line: 133,
                        column: 86
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 133,
                        column: 62
                    },
                    end: {
                        line: 133,
                        column: 79
                    }
                }, {
                    start: {
                        line: 133,
                        column: 82
                    },
                    end: {
                        line: 133,
                        column: 86
                    }
                }],
                line: 133
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_11jt39av3r.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_11jt39av3r.f[0]++;
    cov_11jt39av3r.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_11jt39av3r.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_11jt39av3r.s[3]++;
    $stateProvider.state('login', {
        url: '/login',
        templateUrl: 'app/components/auth/views/auth.login.view.html',
        controller: 'AuthLoginController',
        controllerAs: 'vm'
    }).state('logout', {
        url: '/logout',
        template: '<div></div>',
        controller: 'AuthLogoutController',
        controllerAs: 'vm'
    }).state('profile', {
        url: '/perfil',
        template: '<edit-profile user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user($rootScope, UsersTable) {
                cov_11jt39av3r.f[1]++;
                cov_11jt39av3r.s[4]++;
                return UsersTable.$find($rootScope.me.id).$then(function (user) {
                    cov_11jt39av3r.f[2]++;
                    cov_11jt39av3r.s[5]++;
                    return user;
                }).$promise;
            }
        }
    }).state('forgot', {
        url: '/recuperar_password',
        templateUrl: 'app/components/auth/views/auth.forgot.html',
        controller: 'AuthForgotController',
        controllerAs: 'vm'
    }).state('set_password', {
        url: '/reset_password?token=&email=',
        templateUrl: 'app/components/auth/views/auth.set.password.html',
        controller: 'AuthSetPasswordController',
        controllerAs: 'vm'
    }).state('register', {
        url: '/criar_conta',
        templateUrl: 'app/components/auth/views/auth.register.view.html',
        controller: 'AuthRegisterController',
        controllerAs: 'vm'
    }).state('activate', {
        url: '/activate?token=',
        template: '<div></div>',
        controller: 'AuthActivateController',
        controllerAs: 'vm'
    }).state('usersList', {
        url: '/utilizadores',
        template: '<users-list members="$resolve.members" accountants="$resolve.accountants" flex layout="column"/>',
        resolve: {
            members: function members(UsersTable) {
                cov_11jt39av3r.f[3]++;
                cov_11jt39av3r.s[6]++;
                return UsersTable.$search({
                    type: 'MEMBER'
                }).$then(function (members) {
                    cov_11jt39av3r.f[4]++;
                    cov_11jt39av3r.s[7]++;
                    return members;
                }).$promise;
            },
            accountants: function accountants(UsersTable) {
                cov_11jt39av3r.f[5]++;
                cov_11jt39av3r.s[8]++;
                return UsersTable.$search({
                    type: 'ACCOUNTANT'
                }).$then(function (accountants) {
                    cov_11jt39av3r.f[6]++;
                    cov_11jt39av3r.s[9]++;
                    return accountants;
                }).$promise;
            }
        }
    }).state('projectsList', {
        url: '/projects',
        template: '<projects-list projects="$resolve.projects" flex layout="column"/>',
        resolve: {
            projects: function projects(ProjectsTable) {
                cov_11jt39av3r.f[7]++;
                cov_11jt39av3r.s[10]++;
                return ProjectsTable.$search().$then(function (projects) {
                    cov_11jt39av3r.f[8]++;
                    cov_11jt39av3r.s[11]++;
                    return projects;
                }).$promise;
            }
        }
    }).state('projectDetails', {
        url: '/project/:id',
        template: '<project-details project="$resolve.project" flex layout="column"/>',
        resolve: {
            project: function project(ProjectsTable, $stateParams) {
                cov_11jt39av3r.f[9]++;
                cov_11jt39av3r.s[12]++;
                return ProjectsTable.$find($stateParams.id).$then(function (project) {
                    cov_11jt39av3r.f[10]++;
                    cov_11jt39av3r.s[13]++;
                    return project;
                }).$promise;
            }
        }
    }).state('recurrentList', {
        url: '/recurrent',
        template: '<recurrent-list recurrents="$resolve.recurrents" flex layout="column"/>',
        resolve: {
            recurrents: function recurrents(RecurrentTable) {
                cov_11jt39av3r.f[11]++;
                cov_11jt39av3r.s[14]++;
                return RecurrentTable.$search().$then(function (recurrents) {
                    cov_11jt39av3r.f[12]++;
                    cov_11jt39av3r.s[15]++;
                    return recurrents;
                }).$promise;
            }
        }
    }).state('recurrentDetails', {
        url: '/recurrent/:id',
        template: '<recurrent-details recurrent="$resolve.recurrent" flex layout="column"/>',
        resolve: {
            recurrent: function recurrent(RecurrentTable, $stateParams) {
                cov_11jt39av3r.f[13]++;
                cov_11jt39av3r.s[16]++;
                return RecurrentTable.$find($stateParams.id).$then(function (recurrent) {
                    cov_11jt39av3r.f[14]++;
                    cov_11jt39av3r.s[17]++;
                    return recurrent;
                }).$promise;
            }
        }
    }).state('statisticsList', {
        url: '/statistics',
        template: '<statistics-list statistics="$resolve.statistics" flex layout="column"/>',
        resolve: {
            statistics: function statistics(StatisticsTable) {
                cov_11jt39av3r.f[15]++;
                cov_11jt39av3r.s[18]++;
                return StatisticsTable.$search().$then(function (statistics) {
                    cov_11jt39av3r.f[16]++;
                    cov_11jt39av3r.s[19]++;
                    return statistics;
                }).$promise;
            }
        }
    }).state('statisticDetails', {
        url: '/statistic/:project',
        template: '<statistic-details statistics="$resolve.statistics" flex layout="column"/>',
        resolve: {
            statistics: function statistics(StatisticsTable, $stateParams) {
                cov_11jt39av3r.f[17]++;
                cov_11jt39av3r.s[20]++;
                return StatisticsTable.$search({ 'project': $stateParams.project }).$then(function (statistics) {
                    cov_11jt39av3r.f[18]++;
                    cov_11jt39av3r.s[21]++;
                    return statistics;
                }).$promise;
            }
        }
    }).state('dashboard', {
        url: '/',
        controller: 'DashboardController',
        controllerAs: 'vm',
        template: '<div></div>'
    }).state('userProfile', {
        url: '/user/:id',
        template: '<user-profile user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user(UsersTable, $stateParams) {
                cov_11jt39av3r.f[19]++;
                cov_11jt39av3r.s[22]++;
                return UsersTable.$find($stateParams.id).$then(function (user) {
                    cov_11jt39av3r.f[20]++;
                    cov_11jt39av3r.s[23]++;
                    return user;
                }).$promise;
            }
        }
    }).state('userHistory', {
        url: '/user/history/:id',
        template: '<user-history user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user(UsersTable, $stateParams) {
                cov_11jt39av3r.f[21]++;
                cov_11jt39av3r.s[24]++;
                return UsersTable.$find($stateParams.id).$then(function (user) {
                    cov_11jt39av3r.f[22]++;
                    cov_11jt39av3r.s[25]++;
                    return user;
                }).$promise;
            }
        }
    }).state('reports', {
        url: '/reports/:year',
        template: '<reports-view reports="$resolve.reports" year="$resolve.year"/>',
        resolve: {
            year: function year($stateParams) {
                cov_11jt39av3r.f[23]++;
                cov_11jt39av3r.s[26]++;
                return $stateParams.year ? (cov_11jt39av3r.b[0][0]++, $stateParams.year) : (cov_11jt39av3r.b[0][1]++, 2020);
            },
            reports: function reports(ReportsTable, year) {
                cov_11jt39av3r.f[24]++;
                cov_11jt39av3r.s[27]++;
                return ReportsTable.$search({
                    'is_deleted': '0',
                    year: year
                }).$then(function (reports) {
                    cov_11jt39av3r.f[25]++;
                    cov_11jt39av3r.s[28]++;
                    return reports;
                }).$promise;
            }
        }
    }).state('taskView', {
        url: '/task/:id',
        template: '<task-view task="$resolve.task"/>',
        resolve: {
            task: function task(TasksTable, $stateParams) {
                cov_11jt39av3r.f[26]++;
                cov_11jt39av3r.s[29]++;
                return TasksTable.$find($stateParams.id).$then(function (task) {
                    cov_11jt39av3r.f[27]++;
                    cov_11jt39av3r.s[30]++;
                    return task;
                }).$promise;
            }
        }
    }).state('projectStories', {
        url: '/project/:id/:proposal',
        template: '<project-stories project="$resolve.project" proposal="$resolve.proposal" flex layout="column"/>',
        resolve: {
            project: function project(ProjectsTable, $stateParams) {
                cov_11jt39av3r.f[28]++;
                cov_11jt39av3r.s[31]++;
                return ProjectsTable.$find($stateParams.id).$then(function (project) {
                    cov_11jt39av3r.f[29]++;
                    cov_11jt39av3r.s[32]++;
                    return project;
                }).$promise;
            },
            proposal: function proposal(ProposalsTable, $stateParams) {
                cov_11jt39av3r.f[30]++;
                cov_11jt39av3r.s[33]++;
                return ProposalsTable.$find($stateParams.proposal).$then(function (proposal) {
                    cov_11jt39av3r.f[31]++;
                    cov_11jt39av3r.s[34]++;
                    return proposal;
                }).$promise;
            }
        }
    });
}