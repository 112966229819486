'use strict';

var cov_2i6cb7s4x7 = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.mixins.containable.factory.js',
        hash = '7a72da017719074818bc2e8d05e65dbd3dc24eed',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.mixins.containable.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 147,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 56
                }
            },
            '3': {
                start: {
                    line: 15,
                    column: 16
                },
                end: {
                    line: 15,
                    column: 49
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 21,
                    column: 19
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 20
                },
                end: {
                    line: 17,
                    column: 70
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 20
                },
                end: {
                    line: 20,
                    column: 21
                }
            },
            '7': {
                start: {
                    line: 19,
                    column: 24
                },
                end: {
                    line: 19,
                    column: 51
                }
            },
            '8': {
                start: {
                    line: 25,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 73
                }
            },
            '9': {
                start: {
                    line: 26,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 19
                }
            },
            '10': {
                start: {
                    line: 27,
                    column: 20
                },
                end: {
                    line: 30,
                    column: 21
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 24
                },
                end: {
                    line: 28,
                    column: 82
                }
            },
            '12': {
                start: {
                    line: 29,
                    column: 24
                },
                end: {
                    line: 29,
                    column: 51
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 25
                },
                end: {
                    line: 34,
                    column: 30
                }
            },
            '14': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 13
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 20
                },
                end: {
                    line: 37,
                    column: 49
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 20
                },
                end: {
                    line: 38,
                    column: 26
                }
            },
            '17': {
                start: {
                    line: 40,
                    column: 20
                },
                end: {
                    line: 40,
                    column: 47
                }
            },
            '18': {
                start: {
                    line: 41,
                    column: 20
                },
                end: {
                    line: 41,
                    column: 26
                }
            },
            '19': {
                start: {
                    line: 43,
                    column: 20
                },
                end: {
                    line: 43,
                    column: 103
                }
            },
            '20': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 44
                }
            },
            '21': {
                start: {
                    line: 47,
                    column: 12
                },
                end: {
                    line: 47,
                    column: 37
                }
            },
            '22': {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 49,
                    column: 26
                }
            },
            '23': {
                start: {
                    line: 53,
                    column: 12
                },
                end: {
                    line: 53,
                    column: 56
                }
            },
            '24': {
                start: {
                    line: 57,
                    column: 16
                },
                end: {
                    line: 78,
                    column: 19
                }
            },
            '25': {
                start: {
                    line: 58,
                    column: 31
                },
                end: {
                    line: 60,
                    column: 22
                }
            },
            '26': {
                start: {
                    line: 59,
                    column: 24
                },
                end: {
                    line: 59,
                    column: 54
                }
            },
            '27': {
                start: {
                    line: 62,
                    column: 34
                },
                end: {
                    line: 73,
                    column: 22
                }
            },
            '28': {
                start: {
                    line: 65,
                    column: 39
                },
                end: {
                    line: 65,
                    column: 41
                }
            },
            '29': {
                start: {
                    line: 66,
                    column: 24
                },
                end: {
                    line: 68,
                    column: 27
                }
            },
            '30': {
                start: {
                    line: 67,
                    column: 28
                },
                end: {
                    line: 67,
                    column: 60
                }
            },
            '31': {
                start: {
                    line: 70,
                    column: 24
                },
                end: {
                    line: 72,
                    column: 27
                }
            },
            '32': {
                start: {
                    line: 71,
                    column: 28
                },
                end: {
                    line: 71,
                    column: 80
                }
            },
            '33': {
                start: {
                    line: 74,
                    column: 20
                },
                end: {
                    line: 76,
                    column: 23
                }
            },
            '34': {
                start: {
                    line: 75,
                    column: 24
                },
                end: {
                    line: 75,
                    column: 50
                }
            },
            '35': {
                start: {
                    line: 77,
                    column: 20
                },
                end: {
                    line: 77,
                    column: 52
                }
            },
            '36': {
                start: {
                    line: 82,
                    column: 16
                },
                end: {
                    line: 82,
                    column: 80
                }
            },
            '37': {
                start: {
                    line: 85,
                    column: 25
                },
                end: {
                    line: 85,
                    column: 30
                }
            },
            '38': {
                start: {
                    line: 86,
                    column: 12
                },
                end: {
                    line: 95,
                    column: 13
                }
            },
            '39': {
                start: {
                    line: 88,
                    column: 20
                },
                end: {
                    line: 88,
                    column: 53
                }
            },
            '40': {
                start: {
                    line: 89,
                    column: 20
                },
                end: {
                    line: 89,
                    column: 26
                }
            },
            '41': {
                start: {
                    line: 91,
                    column: 20
                },
                end: {
                    line: 91,
                    column: 51
                }
            },
            '42': {
                start: {
                    line: 92,
                    column: 20
                },
                end: {
                    line: 92,
                    column: 26
                }
            },
            '43': {
                start: {
                    line: 94,
                    column: 20
                },
                end: {
                    line: 94,
                    column: 103
                }
            },
            '44': {
                start: {
                    line: 97,
                    column: 12
                },
                end: {
                    line: 97,
                    column: 44
                }
            },
            '45': {
                start: {
                    line: 98,
                    column: 12
                },
                end: {
                    line: 98,
                    column: 37
                }
            },
            '46': {
                start: {
                    line: 100,
                    column: 12
                },
                end: {
                    line: 100,
                    column: 30
                }
            },
            '47': {
                start: {
                    line: 104,
                    column: 27
                },
                end: {
                    line: 104,
                    column: 62
                }
            },
            '48': {
                start: {
                    line: 105,
                    column: 12
                },
                end: {
                    line: 116,
                    column: 13
                }
            },
            '49': {
                start: {
                    line: 106,
                    column: 29
                },
                end: {
                    line: 106,
                    column: 65
                }
            },
            '50': {
                start: {
                    line: 107,
                    column: 16
                },
                end: {
                    line: 107,
                    column: 60
                }
            },
            '51': {
                start: {
                    line: 108,
                    column: 16
                },
                end: {
                    line: 112,
                    column: 19
                }
            },
            '52': {
                start: {
                    line: 109,
                    column: 20
                },
                end: {
                    line: 111,
                    column: 21
                }
            },
            '53': {
                start: {
                    line: 110,
                    column: 24
                },
                end: {
                    line: 110,
                    column: 54
                }
            },
            '54': {
                start: {
                    line: 113,
                    column: 16
                },
                end: {
                    line: 113,
                    column: 30
                }
            },
            '55': {
                start: {
                    line: 115,
                    column: 16
                },
                end: {
                    line: 115,
                    column: 92
                }
            },
            '56': {
                start: {
                    line: 119,
                    column: 8
                },
                end: {
                    line: 145,
                    column: 11
                }
            },
            '57': {
                start: {
                    line: 124,
                    column: 38
                },
                end: {
                    line: 124,
                    column: 73
                }
            },
            '58': {
                start: {
                    line: 125,
                    column: 24
                },
                end: {
                    line: 125,
                    column: 95
                }
            },
            '59': {
                start: {
                    line: 126,
                    column: 24
                },
                end: {
                    line: 126,
                    column: 94
                }
            },
            '60': {
                start: {
                    line: 131,
                    column: 38
                },
                end: {
                    line: 131,
                    column: 73
                }
            },
            '61': {
                start: {
                    line: 132,
                    column: 24
                },
                end: {
                    line: 132,
                    column: 95
                }
            },
            '62': {
                start: {
                    line: 133,
                    column: 24
                },
                end: {
                    line: 133,
                    column: 90
                }
            },
            '63': {
                start: {
                    line: 139,
                    column: 20
                },
                end: {
                    line: 141,
                    column: 21
                }
            },
            '64': {
                start: {
                    line: 140,
                    column: 24
                },
                end: {
                    line: 140,
                    column: 41
                }
            },
            '65': {
                start: {
                    line: 142,
                    column: 20
                },
                end: {
                    line: 142,
                    column: 65
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 147,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 49
                    },
                    end: {
                        line: 146,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'recordLoad',
                decl: {
                    start: {
                        line: 11,
                        column: 17
                    },
                    end: {
                        line: 11,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 69
                    },
                    end: {
                        line: 50,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: 'recordLoadBelongsTo',
                decl: {
                    start: {
                        line: 14,
                        column: 21
                    },
                    end: {
                        line: 14,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 49
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                },
                line: 14
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 16,
                        column: 29
                    },
                    end: {
                        line: 16,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 41
                    },
                    end: {
                        line: 21,
                        column: 17
                    }
                },
                line: 16
            },
            '5': {
                name: 'recordLoadHasMany',
                decl: {
                    start: {
                        line: 24,
                        column: 21
                    },
                    end: {
                        line: 24,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 47
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                line: 24
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 26,
                        column: 29
                    },
                    end: {
                        line: 26,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 41
                    },
                    end: {
                        line: 31,
                        column: 17
                    }
                },
                line: 26
            },
            '7': {
                name: 'collectionLoad',
                decl: {
                    start: {
                        line: 52,
                        column: 17
                    },
                    end: {
                        line: 52,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 77
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                },
                line: 52
            },
            '8': {
                name: 'collectionLoadBelongsTo',
                decl: {
                    start: {
                        line: 56,
                        column: 21
                    },
                    end: {
                        line: 56,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 53
                    },
                    end: {
                        line: 79,
                        column: 13
                    }
                },
                line: 56
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 57,
                        column: 33
                    },
                    end: {
                        line: 57,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 45
                    },
                    end: {
                        line: 78,
                        column: 17
                    }
                },
                line: 57
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 58,
                        column: 46
                    },
                    end: {
                        line: 58,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 61
                    },
                    end: {
                        line: 60,
                        column: 21
                    }
                },
                line: 58
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 64,
                        column: 29
                    },
                    end: {
                        line: 64,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 45
                    },
                    end: {
                        line: 73,
                        column: 21
                    }
                },
                line: 64
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 66,
                        column: 46
                    },
                    end: {
                        line: 66,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 65
                    },
                    end: {
                        line: 68,
                        column: 25
                    }
                },
                line: 66
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 70,
                        column: 52
                    },
                    end: {
                        line: 70,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 68
                    },
                    end: {
                        line: 72,
                        column: 25
                    }
                },
                line: 70
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 74,
                        column: 48
                    },
                    end: {
                        line: 74,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 64
                    },
                    end: {
                        line: 76,
                        column: 21
                    }
                },
                line: 74
            },
            '15': {
                name: 'collectionLoadHasMany',
                decl: {
                    start: {
                        line: 81,
                        column: 21
                    },
                    end: {
                        line: 81,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 51
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                },
                line: 81
            },
            '16': {
                name: 'getModelConfigs',
                decl: {
                    start: {
                        line: 103,
                        column: 17
                    },
                    end: {
                        line: 103,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 103,
                        column: 55
                    },
                    end: {
                        line: 117,
                        column: 9
                    }
                },
                line: 103
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 108,
                        column: 48
                    },
                    end: {
                        line: 108,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 108,
                        column: 70
                    },
                    end: {
                        line: 112,
                        column: 17
                    }
                },
                line: 108
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 123,
                        column: 27
                    },
                    end: {
                        line: 123,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 123,
                        column: 70
                    },
                    end: {
                        line: 127,
                        column: 21
                    }
                },
                line: 123
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 130,
                        column: 27
                    },
                    end: {
                        line: 130,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 130,
                        column: 70
                    },
                    end: {
                        line: 134,
                        column: 21
                    }
                },
                line: 130
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 138,
                        column: 37
                    },
                    end: {
                        line: 138,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 53
                    },
                    end: {
                        line: 143,
                        column: 17
                    }
                },
                line: 138
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 12,
                        column: 32
                    },
                    end: {
                        line: 12,
                        column: 55
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 12,
                        column: 32
                    },
                    end: {
                        line: 12,
                        column: 49
                    }
                }, {
                    start: {
                        line: 12,
                        column: 53
                    },
                    end: {
                        line: 12,
                        column: 55
                    }
                }],
                line: 12
            },
            '1': {
                loc: {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 21
                    }
                }, {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 20,
                        column: 21
                    }
                }],
                line: 18
            },
            '2': {
                loc: {
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 30,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 30,
                        column: 21
                    }
                }, {
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 30,
                        column: 21
                    }
                }],
                line: 27
            },
            '3': {
                loc: {
                    start: {
                        line: 35,
                        column: 12
                    },
                    end: {
                        line: 44,
                        column: 13
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 36,
                        column: 16
                    },
                    end: {
                        line: 38,
                        column: 26
                    }
                }, {
                    start: {
                        line: 39,
                        column: 16
                    },
                    end: {
                        line: 41,
                        column: 26
                    }
                }, {
                    start: {
                        line: 42,
                        column: 16
                    },
                    end: {
                        line: 43,
                        column: 103
                    }
                }],
                line: 35
            },
            '4': {
                loc: {
                    start: {
                        line: 53,
                        column: 32
                    },
                    end: {
                        line: 53,
                        column: 55
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 53,
                        column: 32
                    },
                    end: {
                        line: 53,
                        column: 49
                    }
                }, {
                    start: {
                        line: 53,
                        column: 53
                    },
                    end: {
                        line: 53,
                        column: 55
                    }
                }],
                line: 53
            },
            '5': {
                loc: {
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 95,
                        column: 13
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 87,
                        column: 16
                    },
                    end: {
                        line: 89,
                        column: 26
                    }
                }, {
                    start: {
                        line: 90,
                        column: 16
                    },
                    end: {
                        line: 92,
                        column: 26
                    }
                }, {
                    start: {
                        line: 93,
                        column: 16
                    },
                    end: {
                        line: 94,
                        column: 103
                    }
                }],
                line: 86
            },
            '6': {
                loc: {
                    start: {
                        line: 105,
                        column: 12
                    },
                    end: {
                        line: 116,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 105,
                        column: 12
                    },
                    end: {
                        line: 116,
                        column: 13
                    }
                }, {
                    start: {
                        line: 105,
                        column: 12
                    },
                    end: {
                        line: 116,
                        column: 13
                    }
                }],
                line: 105
            },
            '7': {
                loc: {
                    start: {
                        line: 105,
                        column: 16
                    },
                    end: {
                        line: 105,
                        column: 50
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 105,
                        column: 16
                    },
                    end: {
                        line: 105,
                        column: 24
                    }
                }, {
                    start: {
                        line: 105,
                        column: 28
                    },
                    end: {
                        line: 105,
                        column: 50
                    }
                }],
                line: 105
            },
            '8': {
                loc: {
                    start: {
                        line: 107,
                        column: 36
                    },
                    end: {
                        line: 107,
                        column: 59
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 107,
                        column: 36
                    },
                    end: {
                        line: 107,
                        column: 53
                    }
                }, {
                    start: {
                        line: 107,
                        column: 57
                    },
                    end: {
                        line: 107,
                        column: 59
                    }
                }],
                line: 107
            },
            '9': {
                loc: {
                    start: {
                        line: 109,
                        column: 20
                    },
                    end: {
                        line: 111,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 109,
                        column: 20
                    },
                    end: {
                        line: 111,
                        column: 21
                    }
                }, {
                    start: {
                        line: 109,
                        column: 20
                    },
                    end: {
                        line: 111,
                        column: 21
                    }
                }],
                line: 109
            },
            '10': {
                loc: {
                    start: {
                        line: 139,
                        column: 20
                    },
                    end: {
                        line: 141,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 139,
                        column: 20
                    },
                    end: {
                        line: 141,
                        column: 21
                    }
                }, {
                    start: {
                        line: 139,
                        column: 20
                    },
                    end: {
                        line: 141,
                        column: 21
                    }
                }],
                line: 139
            },
            '11': {
                loc: {
                    start: {
                        line: 142,
                        column: 47
                    },
                    end: {
                        line: 142,
                        column: 64
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 142,
                        column: 47
                    },
                    end: {
                        line: 142,
                        column: 58
                    }
                }, {
                    start: {
                        line: 142,
                        column: 62
                    },
                    end: {
                        line: 142,
                        column: 64
                    }
                }],
                line: 142
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0, 0],
            '4': [0, 0],
            '5': [0, 0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2i6cb7s4x7.s[0]++;
(function () {
    'use strict';

    cov_2i6cb7s4x7.f[0]++;
    cov_2i6cb7s4x7.s[1]++;
    angular.module('app').factory('MixinContainable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod, $injector) {
        cov_2i6cb7s4x7.f[1]++;


        function recordLoad(record, key, config, additionalFilters) {
            cov_2i6cb7s4x7.f[2]++;
            cov_2i6cb7s4x7.s[2]++;

            additionalFilters = (cov_2i6cb7s4x7.b[0][0]++, additionalFilters) || (cov_2i6cb7s4x7.b[0][1]++, {});

            function recordLoadBelongsTo(table) {
                cov_2i6cb7s4x7.f[3]++;
                cov_2i6cb7s4x7.s[3]++;

                record['$' + key] = table.$new();
                cov_2i6cb7s4x7.s[4]++;
                record.$then(function () {
                    cov_2i6cb7s4x7.f[4]++;
                    cov_2i6cb7s4x7.s[5]++;

                    record['$' + key].$pk = record[config.foreignKey];
                    cov_2i6cb7s4x7.s[6]++;
                    if (record[config.foreignKey]) {
                        cov_2i6cb7s4x7.b[1][0]++;
                        cov_2i6cb7s4x7.s[7]++;

                        record['$' + key].$fetch();
                    } else {
                        cov_2i6cb7s4x7.b[1][1]++;
                    }
                });
            }

            function recordLoadHasMany(table) {
                cov_2i6cb7s4x7.f[5]++;
                cov_2i6cb7s4x7.s[8]++;

                record['$' + key] = table.$collection(additionalFilters);
                cov_2i6cb7s4x7.s[9]++;
                record.$then(function () {
                    cov_2i6cb7s4x7.f[6]++;
                    cov_2i6cb7s4x7.s[10]++;

                    if (record.$pk) {
                        cov_2i6cb7s4x7.b[2][0]++;
                        cov_2i6cb7s4x7.s[11]++;

                        record['$' + key].$params[config.foreignKey] = record.$pk;
                        cov_2i6cb7s4x7.s[12]++;
                        record['$' + key].$fetch();
                    } else {
                        cov_2i6cb7s4x7.b[2][1]++;
                    }
                });
            }

            var loader = (cov_2i6cb7s4x7.s[13]++, false);
            cov_2i6cb7s4x7.s[14]++;
            switch (config.type) {
                case 'belongsTo':
                    cov_2i6cb7s4x7.b[3][0]++;
                    cov_2i6cb7s4x7.s[15]++;

                    loader = recordLoadBelongsTo;
                    cov_2i6cb7s4x7.s[16]++;
                    break;
                case 'hasMany':
                    cov_2i6cb7s4x7.b[3][1]++;
                    cov_2i6cb7s4x7.s[17]++;

                    loader = recordLoadHasMany;
                    cov_2i6cb7s4x7.s[18]++;
                    break;
                default:
                    cov_2i6cb7s4x7.b[3][2]++;
                    cov_2i6cb7s4x7.s[19]++;

                    throw new Error('Unknown contain type "' + config.type + '" for MixinContainable');
            }

            cov_2i6cb7s4x7.s[20]++;
            loader.$inject = [config.table];
            cov_2i6cb7s4x7.s[21]++;
            $injector.invoke(loader);

            cov_2i6cb7s4x7.s[22]++;
            return record;
        }

        function collectionLoad(collection, key, config, additionalFilters) {
            cov_2i6cb7s4x7.f[7]++;
            cov_2i6cb7s4x7.s[23]++;

            additionalFilters = (cov_2i6cb7s4x7.b[4][0]++, additionalFilters) || (cov_2i6cb7s4x7.b[4][1]++, {});

            function collectionLoadBelongsTo(table) {
                cov_2i6cb7s4x7.f[8]++;
                cov_2i6cb7s4x7.s[24]++;

                collection.$then(function () {
                    cov_2i6cb7s4x7.f[9]++;

                    var keys = (cov_2i6cb7s4x7.s[25]++, collection.map(function (obj) {
                        cov_2i6cb7s4x7.f[10]++;
                        cov_2i6cb7s4x7.s[26]++;

                        return obj[config.foreignKey];
                    }));

                    var request = (cov_2i6cb7s4x7.s[27]++, table.$search({
                        'id__in': keys.join(',')
                    }).$then(function (data) {
                        cov_2i6cb7s4x7.f[11]++;

                        var objsById = (cov_2i6cb7s4x7.s[28]++, {});
                        cov_2i6cb7s4x7.s[29]++;
                        angular.forEach(data, function (respObj) {
                            cov_2i6cb7s4x7.f[12]++;
                            cov_2i6cb7s4x7.s[30]++;

                            objsById[respObj.$pk] = respObj;
                        });

                        cov_2i6cb7s4x7.s[31]++;
                        angular.forEach(collection, function (item) {
                            cov_2i6cb7s4x7.f[13]++;
                            cov_2i6cb7s4x7.s[32]++;

                            item['$' + key] = objsById[item[config.foreignKey]];
                        });
                    }));
                    cov_2i6cb7s4x7.s[33]++;
                    angular.forEach(collection, function (item) {
                        cov_2i6cb7s4x7.f[14]++;
                        cov_2i6cb7s4x7.s[34]++;

                        item['$' + key] = request;
                    });
                    cov_2i6cb7s4x7.s[35]++;
                    collection['$' + key] = request;
                });
            }

            function collectionLoadHasMany(table) {
                cov_2i6cb7s4x7.f[15]++;
                cov_2i6cb7s4x7.s[36]++;

                throw new Error('Collection load has many Not implemented yet');
            }

            var loader = (cov_2i6cb7s4x7.s[37]++, false);
            cov_2i6cb7s4x7.s[38]++;
            switch (config.type) {
                case 'belongsTo':
                    cov_2i6cb7s4x7.b[5][0]++;
                    cov_2i6cb7s4x7.s[39]++;

                    loader = collectionLoadBelongsTo;
                    cov_2i6cb7s4x7.s[40]++;
                    break;
                case 'hasMany':
                    cov_2i6cb7s4x7.b[5][1]++;
                    cov_2i6cb7s4x7.s[41]++;

                    loader = collectionLoadHasMany;
                    cov_2i6cb7s4x7.s[42]++;
                    break;
                default:
                    cov_2i6cb7s4x7.b[5][2]++;
                    cov_2i6cb7s4x7.s[43]++;

                    throw new Error('Unknown contain type "' + config.type + '" for MixinContainable');
            }

            cov_2i6cb7s4x7.s[44]++;
            loader.$inject = [config.table];
            cov_2i6cb7s4x7.s[45]++;
            $injector.invoke(loader);

            cov_2i6cb7s4x7.s[46]++;
            return collection;
        }

        function getModelConfigs(model, relatedModel) {
            cov_2i6cb7s4x7.f[16]++;

            var contains = (cov_2i6cb7s4x7.s[47]++, model.$type.getProperty('contains'));
            cov_2i6cb7s4x7.s[48]++;
            if ((cov_2i6cb7s4x7.b[7][0]++, contains) && (cov_2i6cb7s4x7.b[7][1]++, contains[relatedModel])) {
                cov_2i6cb7s4x7.b[6][0]++;

                var config = (cov_2i6cb7s4x7.s[49]++, angular.copy(contains[relatedModel]));
                cov_2i6cb7s4x7.s[50]++;
                config['filters'] = (cov_2i6cb7s4x7.b[8][0]++, config['filters']) || (cov_2i6cb7s4x7.b[8][1]++, {});
                cov_2i6cb7s4x7.s[51]++;
                angular.forEach(config.filters, function (value, key) {
                    cov_2i6cb7s4x7.f[17]++;
                    cov_2i6cb7s4x7.s[52]++;

                    if (angular.isFunction(config.filters[key])) {
                        cov_2i6cb7s4x7.b[9][0]++;
                        cov_2i6cb7s4x7.s[53]++;

                        config.filters[key] = value();
                    } else {
                        cov_2i6cb7s4x7.b[9][1]++;
                    }
                });
                cov_2i6cb7s4x7.s[54]++;
                return config;
            } else {
                cov_2i6cb7s4x7.b[6][1]++;
                cov_2i6cb7s4x7.s[55]++;

                throw new Error('Cannot find MixinContainable configs for ' + relatedModel);
            }
        }

        cov_2i6cb7s4x7.s[56]++;
        return restmod.mixin({
            $extend: {
                Collection: {
                    $limit: 25,
                    $load: function $load(relatedModel, additionalFilters) {
                        cov_2i6cb7s4x7.f[18]++;

                        var configs = (cov_2i6cb7s4x7.s[57]++, getModelConfigs(this, relatedModel));
                        cov_2i6cb7s4x7.s[58]++;
                        additionalFilters = angular.extend(configs.filters, additionalFilters);
                        cov_2i6cb7s4x7.s[59]++;
                        return collectionLoad(this, relatedModel, configs, additionalFilters);
                    }
                },
                Record: {
                    $load: function $load(relatedModel, additionalFilters) {
                        cov_2i6cb7s4x7.f[19]++;

                        var configs = (cov_2i6cb7s4x7.s[60]++, getModelConfigs(this, relatedModel));
                        cov_2i6cb7s4x7.s[61]++;
                        additionalFilters = angular.extend(configs.filters, additionalFilters);
                        cov_2i6cb7s4x7.s[62]++;
                        return recordLoad(this, relatedModel, configs, additionalFilters);
                    }
                }
            },
            $hooks: {
                'before-fetch-many': function beforeFetchMany(_req) {
                    cov_2i6cb7s4x7.f[20]++;
                    cov_2i6cb7s4x7.s[63]++;

                    if (_.isUndefined(_req.params)) {
                        cov_2i6cb7s4x7.b[10][0]++;
                        cov_2i6cb7s4x7.s[64]++;

                        _req.params = {};
                    } else {
                        cov_2i6cb7s4x7.b[10][1]++;
                    }
                    cov_2i6cb7s4x7.s[65]++;
                    _req.params['page_size'] = (cov_2i6cb7s4x7.b[11][0]++, this.$limit) || (cov_2i6cb7s4x7.b[11][1]++, 25);
                }
            }
        });
    }
})();