'use strict';

var cov_28ot2wo4ax = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/auth/auth.run.js',
        hash = '64a38c3b59085e0dcbf2cd4629219a294be08ece',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/auth/auth.run.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 27,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 23
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 13
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 15
                },
                end: {
                    line: 13,
                    column: 34
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 38
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 23,
                    column: 15
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 22,
                    column: 62
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 27,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 76
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 33
                    },
                    end: {
                        line: 11,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 50
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 56
                    },
                    end: {
                        line: 20,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 68
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 21,
                        column: 15
                    },
                    end: {
                        line: 21,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 27
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                },
                line: 21
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }, {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }],
                line: 12
            },
            '1': {
                loc: {
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 12,
                        column: 201
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 12,
                        column: 30
                    }
                }, {
                    start: {
                        line: 12,
                        column: 35
                    },
                    end: {
                        line: 12,
                        column: 62
                    }
                }, {
                    start: {
                        line: 12,
                        column: 66
                    },
                    end: {
                        line: 12,
                        column: 94
                    }
                }, {
                    start: {
                        line: 12,
                        column: 98
                    },
                    end: {
                        line: 12,
                        column: 132
                    }
                }, {
                    start: {
                        line: 12,
                        column: 136
                    },
                    end: {
                        line: 12,
                        column: 166
                    }
                }, {
                    start: {
                        line: 12,
                        column: 170
                    },
                    end: {
                        line: 12,
                        column: 200
                    }
                }],
                line: 12
            },
            '2': {
                loc: {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 24,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 24,
                        column: 9
                    }
                }, {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 24,
                        column: 9
                    }
                }],
                line: 19
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0, 0, 0, 0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_28ot2wo4ax.s[0]++;
(function () {
    'use strict';

    cov_28ot2wo4ax.f[0]++;
    cov_28ot2wo4ax.s[1]++;
    angular.module('app').run(runBlock);

    /** @ngInject */
    function runBlock($rootScope, $state, $auth, AuthService, $transitions) {
        cov_28ot2wo4ax.f[1]++;
        cov_28ot2wo4ax.s[2]++;


        $transitions.onStart({}, function (trans) {
            cov_28ot2wo4ax.f[2]++;
            cov_28ot2wo4ax.s[3]++;

            if ((cov_28ot2wo4ax.b[1][0]++, !$rootScope.me) && (cov_28ot2wo4ax.b[1][1]++, trans.to().name !== 'login') && (cov_28ot2wo4ax.b[1][2]++, trans.to().name !== 'forgot') && (cov_28ot2wo4ax.b[1][3]++, trans.to().name !== 'set_password') && (cov_28ot2wo4ax.b[1][4]++, trans.to().name !== 'register') && (cov_28ot2wo4ax.b[1][5]++, trans.to().name !== 'activate')) {
                cov_28ot2wo4ax.b[0][0]++;
                cov_28ot2wo4ax.s[4]++;

                $state.go("login");
            } else {
                cov_28ot2wo4ax.b[0][1]++;
            }

            cov_28ot2wo4ax.s[5]++;
            $rootScope.closeSidebar();
        });

        cov_28ot2wo4ax.s[6]++;
        if ($auth.isAuthenticated()) {
            cov_28ot2wo4ax.b[2][0]++;
            cov_28ot2wo4ax.s[7]++;

            AuthService.refreshAuthenticatedInfo().then(function () {
                cov_28ot2wo4ax.f[3]++;
            }, function () {
                cov_28ot2wo4ax.f[4]++;
                cov_28ot2wo4ax.s[8]++;

                AuthService.logout().then($state.go('login'));
            });
        } else {
            cov_28ot2wo4ax.b[2][1]++;
        }
    }
})();