'use strict';

var cov_ipyzih9ki = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/js/strings.js',
        hash = '491ddaeca2a6bb50fcc62cf72025a47f15b68f66',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/js/strings.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 2
                }
            },
            '1': {
                start: {
                    line: 2,
                    column: 4
                },
                end: {
                    line: 2,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 0
                },
                end: {
                    line: 10,
                    column: 2
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 9,
                    column: 7
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 49
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 2
                }
            },
            '6': {
                start: {
                    line: 14,
                    column: 4
                },
                end: {
                    line: 16,
                    column: 7
                }
            },
            '7': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 20,
                    column: 0
                },
                end: {
                    line: 24,
                    column: 2
                }
            },
            '9': {
                start: {
                    line: 21,
                    column: 4
                },
                end: {
                    line: 23,
                    column: 7
                }
            },
            '10': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 38
                }
            },
            '11': {
                start: {
                    line: 26,
                    column: 0
                },
                end: {
                    line: 34,
                    column: 2
                }
            },
            '12': {
                start: {
                    line: 27,
                    column: 18
                },
                end: {
                    line: 27,
                    column: 36
                }
            },
            '13': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 42
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 18
                },
                end: {
                    line: 29,
                    column: 61
                }
            },
            '15': {
                start: {
                    line: 31,
                    column: 4
                },
                end: {
                    line: 31,
                    column: 42
                }
            },
            '16': {
                start: {
                    line: 31,
                    column: 21
                },
                end: {
                    line: 31,
                    column: 41
                }
            },
            '17': {
                start: {
                    line: 32,
                    column: 4
                },
                end: {
                    line: 32,
                    column: 48
                }
            },
            '18': {
                start: {
                    line: 32,
                    column: 23
                },
                end: {
                    line: 32,
                    column: 47
                }
            },
            '19': {
                start: {
                    line: 33,
                    column: 4
                },
                end: {
                    line: 33,
                    column: 33
                }
            },
            '20': {
                start: {
                    line: 36,
                    column: 0
                },
                end: {
                    line: 46,
                    column: 2
                }
            },
            '21': {
                start: {
                    line: 37,
                    column: 18
                },
                end: {
                    line: 37,
                    column: 36
                }
            },
            '22': {
                start: {
                    line: 38,
                    column: 16
                },
                end: {
                    line: 38,
                    column: 42
                }
            },
            '23': {
                start: {
                    line: 39,
                    column: 18
                },
                end: {
                    line: 39,
                    column: 61
                }
            },
            '24': {
                start: {
                    line: 40,
                    column: 18
                },
                end: {
                    line: 40,
                    column: 59
                }
            },
            '25': {
                start: {
                    line: 42,
                    column: 4
                },
                end: {
                    line: 42,
                    column: 42
                }
            },
            '26': {
                start: {
                    line: 42,
                    column: 21
                },
                end: {
                    line: 42,
                    column: 41
                }
            },
            '27': {
                start: {
                    line: 43,
                    column: 4
                },
                end: {
                    line: 43,
                    column: 48
                }
            },
            '28': {
                start: {
                    line: 43,
                    column: 23
                },
                end: {
                    line: 43,
                    column: 47
                }
            },
            '29': {
                start: {
                    line: 44,
                    column: 4
                },
                end: {
                    line: 44,
                    column: 48
                }
            },
            '30': {
                start: {
                    line: 44,
                    column: 23
                },
                end: {
                    line: 44,
                    column: 47
                }
            },
            '31': {
                start: {
                    line: 45,
                    column: 4
                },
                end: {
                    line: 45,
                    column: 49
                }
            },
            '32': {
                start: {
                    line: 48,
                    column: 0
                },
                end: {
                    line: 55,
                    column: 2
                }
            },
            '33': {
                start: {
                    line: 49,
                    column: 18
                },
                end: {
                    line: 49,
                    column: 36
                }
            },
            '34': {
                start: {
                    line: 50,
                    column: 16
                },
                end: {
                    line: 50,
                    column: 42
                }
            },
            '35': {
                start: {
                    line: 51,
                    column: 18
                },
                end: {
                    line: 51,
                    column: 61
                }
            },
            '36': {
                start: {
                    line: 52,
                    column: 18
                },
                end: {
                    line: 52,
                    column: 59
                }
            },
            '37': {
                start: {
                    line: 54,
                    column: 4
                },
                end: {
                    line: 54,
                    column: 99
                }
            },
            '38': {
                start: {
                    line: 57,
                    column: 0
                },
                end: {
                    line: 66,
                    column: 2
                }
            },
            '39': {
                start: {
                    line: 58,
                    column: 15
                },
                end: {
                    line: 58,
                    column: 29
                }
            },
            '40': {
                start: {
                    line: 59,
                    column: 14
                },
                end: {
                    line: 59,
                    column: 28
                }
            },
            '41': {
                start: {
                    line: 60,
                    column: 16
                },
                end: {
                    line: 60,
                    column: 31
                }
            },
            '42': {
                start: {
                    line: 61,
                    column: 19
                },
                end: {
                    line: 64,
                    column: 5
                }
            },
            '43': {
                start: {
                    line: 65,
                    column: 4
                },
                end: {
                    line: 65,
                    column: 40
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 24
                    },
                    end: {
                        line: 1,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 36
                    },
                    end: {
                        line: 3,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 27
                    },
                    end: {
                        line: 6,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 39
                    },
                    end: {
                        line: 10,
                        column: 1
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 7,
                        column: 38
                    },
                    end: {
                        line: 7,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 52
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 13,
                        column: 26
                    },
                    end: {
                        line: 13,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 38
                    },
                    end: {
                        line: 17,
                        column: 1
                    }
                },
                line: 13
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 14,
                        column: 36
                    },
                    end: {
                        line: 14,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 50
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 14
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 20,
                        column: 32
                    },
                    end: {
                        line: 20,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 44
                    },
                    end: {
                        line: 24,
                        column: 1
                    }
                },
                line: 20
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 21,
                        column: 36
                    },
                    end: {
                        line: 21,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 50
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 21
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 26,
                        column: 26
                    },
                    end: {
                        line: 26,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 38
                    },
                    end: {
                        line: 34,
                        column: 1
                    }
                },
                line: 26
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 36,
                        column: 28
                    },
                    end: {
                        line: 36,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 40
                    },
                    end: {
                        line: 46,
                        column: 1
                    }
                },
                line: 36
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 48,
                        column: 29
                    },
                    end: {
                        line: 48,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 41
                    },
                    end: {
                        line: 55,
                        column: 1
                    }
                },
                line: 48
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 57,
                        column: 31
                    },
                    end: {
                        line: 57,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 42
                    },
                    end: {
                        line: 66,
                        column: 1
                    }
                },
                line: 57
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 42
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 42
                    }
                }, {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 42
                    }
                }],
                line: 31
            },
            '1': {
                loc: {
                    start: {
                        line: 32,
                        column: 4
                    },
                    end: {
                        line: 32,
                        column: 48
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 32,
                        column: 4
                    },
                    end: {
                        line: 32,
                        column: 48
                    }
                }, {
                    start: {
                        line: 32,
                        column: 4
                    },
                    end: {
                        line: 32,
                        column: 48
                    }
                }],
                line: 32
            },
            '2': {
                loc: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 42
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 42
                    }
                }, {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 42
                    }
                }],
                line: 42
            },
            '3': {
                loc: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 48
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 48
                    }
                }, {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 48
                    }
                }],
                line: 43
            },
            '4': {
                loc: {
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 48
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 48
                    }
                }, {
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 48
                    }
                }],
                line: 44
            },
            '5': {
                loc: {
                    start: {
                        line: 54,
                        column: 12
                    },
                    end: {
                        line: 54,
                        column: 43
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 54,
                        column: 26
                    },
                    end: {
                        line: 54,
                        column: 38
                    }
                }, {
                    start: {
                        line: 54,
                        column: 41
                    },
                    end: {
                        line: 54,
                        column: 43
                    }
                }],
                line: 54
            },
            '6': {
                loc: {
                    start: {
                        line: 54,
                        column: 65
                    },
                    end: {
                        line: 54,
                        column: 97
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 54,
                        column: 79
                    },
                    end: {
                        line: 54,
                        column: 81
                    }
                }, {
                    start: {
                        line: 54,
                        column: 84
                    },
                    end: {
                        line: 54,
                        column: 97
                    }
                }],
                line: 54
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_ipyzih9ki.s[0]++;
String.prototype.trim = function () {
    cov_ipyzih9ki.f[0]++;
    cov_ipyzih9ki.s[1]++;

    return this.replace(/^\s+|\s+$/g, "");
};

//To Camel Case
cov_ipyzih9ki.s[2]++;
String.prototype.toCamel = function () {
    cov_ipyzih9ki.f[1]++;
    cov_ipyzih9ki.s[3]++;

    return this.replace(/(\-[a-z])/g, function ($1) {
        cov_ipyzih9ki.f[2]++;
        cov_ipyzih9ki.s[4]++;

        return $1.toUpperCase().replace('-', '');
    });
};

//To Dashed from Camel Case
cov_ipyzih9ki.s[5]++;
String.prototype.toDash = function () {
    cov_ipyzih9ki.f[3]++;
    cov_ipyzih9ki.s[6]++;

    return this.replace(/([A-Z])/g, function ($1) {
        cov_ipyzih9ki.f[4]++;
        cov_ipyzih9ki.s[7]++;

        return "-" + $1.toLowerCase();
    });
};

//To Underscore from Camel Case
cov_ipyzih9ki.s[8]++;
String.prototype.toUnderscore = function () {
    cov_ipyzih9ki.f[5]++;
    cov_ipyzih9ki.s[9]++;

    return this.replace(/([A-Z])/g, function ($1) {
        cov_ipyzih9ki.f[6]++;
        cov_ipyzih9ki.s[10]++;

        return "_" + $1.toLowerCase();
    });
};

cov_ipyzih9ki.s[11]++;
String.prototype.toHHMM = function () {
    cov_ipyzih9ki.f[7]++;

    var sec_num = (cov_ipyzih9ki.s[12]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_ipyzih9ki.s[13]++, Math.floor(sec_num / 3600));
    var minutes = (cov_ipyzih9ki.s[14]++, Math.floor((sec_num - hours * 3600) / 60));

    cov_ipyzih9ki.s[15]++;
    if (hours < 10) {
        cov_ipyzih9ki.b[0][0]++;
        cov_ipyzih9ki.s[16]++;
        hours = "0" + hours;
    } else {
        cov_ipyzih9ki.b[0][1]++;
    }
    cov_ipyzih9ki.s[17]++;
    if (minutes < 10) {
        cov_ipyzih9ki.b[1][0]++;
        cov_ipyzih9ki.s[18]++;
        minutes = "0" + minutes;
    } else {
        cov_ipyzih9ki.b[1][1]++;
    }
    cov_ipyzih9ki.s[19]++;
    return hours + ':' + minutes;
};

cov_ipyzih9ki.s[20]++;
String.prototype.toHHMMSS = function () {
    cov_ipyzih9ki.f[8]++;

    var sec_num = (cov_ipyzih9ki.s[21]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_ipyzih9ki.s[22]++, Math.floor(sec_num / 3600));
    var minutes = (cov_ipyzih9ki.s[23]++, Math.floor((sec_num - hours * 3600) / 60));
    var seconds = (cov_ipyzih9ki.s[24]++, sec_num - hours * 3600 - minutes * 60);

    cov_ipyzih9ki.s[25]++;
    if (hours < 10) {
        cov_ipyzih9ki.b[2][0]++;
        cov_ipyzih9ki.s[26]++;
        hours = "0" + hours;
    } else {
        cov_ipyzih9ki.b[2][1]++;
    }
    cov_ipyzih9ki.s[27]++;
    if (minutes < 10) {
        cov_ipyzih9ki.b[3][0]++;
        cov_ipyzih9ki.s[28]++;
        minutes = "0" + minutes;
    } else {
        cov_ipyzih9ki.b[3][1]++;
    }
    cov_ipyzih9ki.s[29]++;
    if (seconds < 10) {
        cov_ipyzih9ki.b[4][0]++;
        cov_ipyzih9ki.s[30]++;
        seconds = "0" + seconds;
    } else {
        cov_ipyzih9ki.b[4][1]++;
    }
    cov_ipyzih9ki.s[31]++;
    return hours + ':' + minutes + ':' + seconds;
};

cov_ipyzih9ki.s[32]++;
String.prototype.toHhMmSSs = function () {
    cov_ipyzih9ki.f[9]++;

    var sec_num = (cov_ipyzih9ki.s[33]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_ipyzih9ki.s[34]++, Math.floor(sec_num / 3600));
    var minutes = (cov_ipyzih9ki.s[35]++, Math.floor((sec_num - hours * 3600) / 60));
    var seconds = (cov_ipyzih9ki.s[36]++, sec_num - hours * 3600 - minutes * 60);

    cov_ipyzih9ki.s[37]++;
    return (hours > 0 ? (cov_ipyzih9ki.b[5][0]++, hours + 'h ') : (cov_ipyzih9ki.b[5][1]++, '')) + minutes + 'm ' + (hours > 0 ? (cov_ipyzih9ki.b[6][0]++, '') : (cov_ipyzih9ki.b[6][1]++, seconds + 's'));
};

cov_ipyzih9ki.s[38]++;
String.prototype.toDayOfYear = function () {
    cov_ipyzih9ki.f[10]++;

    var date = (cov_ipyzih9ki.s[39]++, new Date(this));
    var day = (cov_ipyzih9ki.s[40]++, date.getDate());
    var month = (cov_ipyzih9ki.s[41]++, date.getMonth());
    var months = (cov_ipyzih9ki.s[42]++, ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']);
    cov_ipyzih9ki.s[43]++;
    return day + ' de ' + months[month];
};