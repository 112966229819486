'use strict';

var cov_1daujr5ona = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/users/editProfile/editProfile.js',
        hash = '380595607b955daab02c8f5d2207416e619c4aaf',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/users/editProfile/editProfile.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 21,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 19
                }
            },
            '7': {
                start: {
                    line: 15,
                    column: 20
                },
                end: {
                    line: 15,
                    column: 99
                }
            },
            '8': {
                start: {
                    line: 16,
                    column: 20
                },
                end: {
                    line: 16,
                    column: 59
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 20
                },
                end: {
                    line: 18,
                    column: 98
                }
            },
            '10': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 11
                }
            },
            '11': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 15
                }
            },
            '12': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 94
                }
            },
            '13': {
                start: {
                    line: 30,
                    column: 16
                },
                end: {
                    line: 30,
                    column: 41
                }
            },
            '14': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 11
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 89
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 67
                }
            },
            '17': {
                start: {
                    line: 43,
                    column: 0
                },
                end: {
                    line: 51,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 68
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 43
                    },
                    end: {
                        line: 8,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 49
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 50
                    },
                    end: {
                        line: 14,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 56
                    },
                    end: {
                        line: 17,
                        column: 17
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 17,
                        column: 19
                    },
                    end: {
                        line: 17,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 25
                    },
                    end: {
                        line: 19,
                        column: 17
                    }
                },
                line: 17
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 18
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 25
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 26,
                        column: 65
                    },
                    end: {
                        line: 26,
                        column: 66
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 71
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 26
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 27,
                        column: 62
                    },
                    end: {
                        line: 27,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 68
                    },
                    end: {
                        line: 29,
                        column: 13
                    }
                },
                line: 27
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 29,
                        column: 15
                    },
                    end: {
                        line: 29,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 21
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                },
                line: 29
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 18
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 35
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 36,
                        column: 64
                    },
                    end: {
                        line: 36,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 70
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                line: 36
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }, {
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }],
                line: 13
            },
            '1': {
                loc: {
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 13,
                        column: 94
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 13,
                        column: 50
                    }
                }, {
                    start: {
                        line: 13,
                        column: 54
                    },
                    end: {
                        line: 13,
                        column: 94
                    }
                }],
                line: 13
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EditProfileController = function () {
    function EditProfileController($mdToast, AuthService, $state, $rootScope, $scope) {
        var _this = this;

        _classCallCheck(this, EditProfileController);

        cov_1daujr5ona.f[0]++;
        cov_1daujr5ona.s[0]++;

        this.$mdToast = $mdToast;
        cov_1daujr5ona.s[1]++;
        this.AuthService = AuthService;
        cov_1daujr5ona.s[2]++;
        this.$state = $state;

        cov_1daujr5ona.s[3]++;
        $scope.$watch('$ctrl.user.avatar', function () {
            cov_1daujr5ona.f[1]++;
            cov_1daujr5ona.s[4]++;

            console.log({
                'this': _this.user.avatar,
                'root': $rootScope.me.avatar
            });
            cov_1daujr5ona.s[5]++;
            if ((cov_1daujr5ona.b[1][0]++, angular.isString(_this.user.avatar)) && (cov_1daujr5ona.b[1][1]++, _this.user.avatar != $rootScope.me.avatar)) {
                cov_1daujr5ona.b[0][0]++;
                cov_1daujr5ona.s[6]++;

                _this.user.$save(['avatar']).$then(function () {
                    cov_1daujr5ona.f[2]++;
                    cov_1daujr5ona.s[7]++;

                    $mdToast.show($mdToast.simple().textContent(gettext('Profile image updated')));
                    cov_1daujr5ona.s[8]++;
                    AuthService.refreshAuthenticatedInfo();
                }, function () {
                    cov_1daujr5ona.f[3]++;
                    cov_1daujr5ona.s[9]++;

                    $mdToast.show($mdToast.simple().textContent(gettext('An error has ocurred')));
                });
            } else {
                cov_1daujr5ona.b[0][1]++;
            }
        });
    }

    _createClass(EditProfileController, [{
        key: 'changeInfo',
        value: function changeInfo() {
            var _this2 = this;

            cov_1daujr5ona.f[4]++;
            cov_1daujr5ona.s[10]++;

            this.user.$save(['name', 'email', 'nif', 'phone']).$then(function () {
                cov_1daujr5ona.f[5]++;
                cov_1daujr5ona.s[11]++;

                _this2.AuthService.refreshAuthenticatedInfo().$then(function () {
                    cov_1daujr5ona.f[6]++;
                    cov_1daujr5ona.s[12]++;

                    _this2.$mdToast.show(_this2.$mdToast.simple().textContent('Perfil actualizado!'));
                }, function () {
                    cov_1daujr5ona.f[7]++;
                    cov_1daujr5ona.s[13]++;

                    _this2.$state.go('logout');
                });
            });
        }
    }, {
        key: 'changePass',
        value: function changePass() {
            var _this3 = this;

            cov_1daujr5ona.f[8]++;
            cov_1daujr5ona.s[14]++;

            this.user.$save(['password', 'confirm_password']).$then(function () {
                cov_1daujr5ona.f[9]++;
                cov_1daujr5ona.s[15]++;

                _this3.$mdToast.show(_this3.$mdToast.simple().textContent('Password alterada!'));
                cov_1daujr5ona.s[16]++;
                _this3.user.password = _this3.user.confirm_password = null;
            });
        }
    }]);

    return EditProfileController;
}();

cov_1daujr5ona.s[17]++;


angular.module('app').component('editProfile', {
    templateUrl: 'app/components/users/editProfile/editProfile.html',
    controller: EditProfileController,
    bindings: {
        user: '<'
    }
});