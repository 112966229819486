'use strict';

var cov_13ppzeyjbr = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.users.factory.js',
        hash = 'bd55419a03f049743ebc736a468277941605b774',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.users.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 68,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 58,
                    column: 16
                },
                end: {
                    line: 64,
                    column: 17
                }
            },
            '4': {
                start: {
                    line: 59,
                    column: 20
                },
                end: {
                    line: 61,
                    column: 21
                }
            },
            '5': {
                start: {
                    line: 60,
                    column: 24
                },
                end: {
                    line: 60,
                    column: 86
                }
            },
            '6': {
                start: {
                    line: 60,
                    column: 63
                },
                end: {
                    line: 60,
                    column: 84
                }
            },
            '7': {
                start: {
                    line: 63,
                    column: 20
                },
                end: {
                    line: 63,
                    column: 82
                }
            },
            '8': {
                start: {
                    line: 63,
                    column: 59
                },
                end: {
                    line: 63,
                    column: 80
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 68,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 57,
                        column: 27
                    },
                    end: {
                        line: 57,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 39
                    },
                    end: {
                        line: 65,
                        column: 13
                    }
                },
                line: 57
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 60,
                        column: 53
                    },
                    end: {
                        line: 60,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 63
                    },
                    end: {
                        line: 60,
                        column: 84
                    }
                },
                line: 60
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 63,
                        column: 49
                    },
                    end: {
                        line: 63,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 63,
                        column: 59
                    },
                    end: {
                        line: 63,
                        column: 80
                    }
                },
                line: 63
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 58,
                        column: 16
                    },
                    end: {
                        line: 64,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 58,
                        column: 16
                    },
                    end: {
                        line: 64,
                        column: 17
                    }
                }, {
                    start: {
                        line: 58,
                        column: 16
                    },
                    end: {
                        line: 64,
                        column: 17
                    }
                }],
                line: 58
            },
            '1': {
                loc: {
                    start: {
                        line: 59,
                        column: 20
                    },
                    end: {
                        line: 61,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 59,
                        column: 20
                    },
                    end: {
                        line: 61,
                        column: 21
                    }
                }, {
                    start: {
                        line: 59,
                        column: 20
                    },
                    end: {
                        line: 61,
                        column: 21
                    }
                }],
                line: 59
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_13ppzeyjbr.s[0]++;
(function () {
    'use strict';

    cov_13ppzeyjbr.f[0]++;
    cov_13ppzeyjbr.s[1]++;
    angular.module('app').factory('UsersTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_13ppzeyjbr.f[1]++;
        cov_13ppzeyjbr.s[2]++;


        return restmod.model('/users').mix('MixinContainable', {
            $extend: {
                Collection: {
                    $limit: 1000
                }
            },
            $config: {
                contains: {
                    tasks: {
                        type: 'hasMany',
                        table: 'TasksTable',
                        foreignKey: 'user'
                    },
                    openTasks: {
                        type: 'hasMany',
                        table: 'TasksTable',
                        foreignKey: 'user',
                        filters: {
                            'state__not_in': 'CLOSED'
                        }
                    },
                    lastTask: {
                        type: 'belongsTo',
                        table: 'TasksTable',
                        foreignKey: 'lastTask'
                    },
                    executions: {
                        type: 'hasMany',
                        table: 'ExecutionsTable',
                        foreignKey: 'task__user',
                        filters: {
                            'ordering': '-start'
                        }
                    },
                    watchers: {
                        type: 'hasMany',
                        table: 'WatchersTable',
                        foreignKey: 'user'
                    },
                    comments: {
                        type: 'hasMany',
                        table: 'ExecutionsTable',
                        foreignKey: 'user'
                    }
                }
            },
            $loadLastTask: function $loadLastTask() {
                cov_13ppzeyjbr.f[2]++;
                cov_13ppzeyjbr.s[3]++;

                if (this.hasOwnProperty('$lastTask')) {
                    cov_13ppzeyjbr.b[0][0]++;
                    cov_13ppzeyjbr.s[4]++;

                    if (this.lastTask != this.$lastTask.id) {
                        cov_13ppzeyjbr.b[1][0]++;
                        cov_13ppzeyjbr.s[5]++;

                        this.$load('lastTask').$then(function (task) {
                            cov_13ppzeyjbr.f[3]++;
                            cov_13ppzeyjbr.s[6]++;
                            return task.$load('project');
                        });
                    } else {
                        cov_13ppzeyjbr.b[1][1]++;
                    }
                } else {
                    cov_13ppzeyjbr.b[0][1]++;
                    cov_13ppzeyjbr.s[7]++;

                    this.$load('lastTask').$then(function (task) {
                        cov_13ppzeyjbr.f[4]++;
                        cov_13ppzeyjbr.s[8]++;
                        return task.$load('project');
                    });
                }
            }
        });
    }
})();