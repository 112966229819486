'use strict';

var cov_10s8ru6nqy = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/auth/auth.service.js',
        hash = '028ca33e7f0f680a04112b5bac728f0765753f9c',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/auth/auth.service.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 46,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 26
                },
                end: {
                    line: 10,
                    column: 30
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 31
                },
                end: {
                    line: 12,
                    column: 78
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 15
                }
            },
            '6': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 14,
                    column: 62
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 29
                }
            },
            '8': {
                start: {
                    line: 19,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 32
                }
            },
            '9': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 10
                }
            },
            '10': {
                start: {
                    line: 23,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 15
                }
            },
            '11': {
                start: {
                    line: 24,
                    column: 16
                },
                end: {
                    line: 24,
                    column: 62
                }
            },
            '12': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 10
                }
            },
            '13': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 30,
                    column: 16
                },
                end: {
                    line: 30,
                    column: 53
                }
            },
            '15': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 37
                }
            },
            '16': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 42
                }
            },
            '17': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 10
                }
            },
            '18': {
                start: {
                    line: 38,
                    column: 32
                },
                end: {
                    line: 38,
                    column: 46
                }
            },
            '19': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 15
                }
            },
            '20': {
                start: {
                    line: 40,
                    column: 16
                },
                end: {
                    line: 40,
                    column: 38
                }
            },
            '21': {
                start: {
                    line: 41,
                    column: 16
                },
                end: {
                    line: 41,
                    column: 40
                }
            },
            '22': {
                start: {
                    line: 43,
                    column: 12
                },
                end: {
                    line: 43,
                    column: 33
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 46,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'service',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 60
                    },
                    end: {
                        line: 45,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 21
                    },
                    end: {
                        line: 11,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 48
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 13,
                        column: 30
                    },
                    end: {
                        line: 13,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 42
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                },
                line: 13
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 15,
                        column: 15
                    },
                    end: {
                        line: 15,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 32
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                line: 15
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 22,
                        column: 29
                    },
                    end: {
                        line: 22,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 41
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 22
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 23,
                        column: 55
                    },
                    end: {
                        line: 23,
                        column: 56
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 67
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                line: 23
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 28,
                        column: 40
                    },
                    end: {
                        line: 28,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 52
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                },
                line: 28
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 29,
                        column: 57
                    },
                    end: {
                        line: 29,
                        column: 58
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 69
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                line: 29
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 37,
                        column: 22
                    },
                    end: {
                        line: 37,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 34
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                },
                line: 37
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 39,
                        column: 31
                    },
                    end: {
                        line: 39,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 43
                    },
                    end: {
                        line: 42,
                        column: 13
                    }
                },
                line: 39
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_10s8ru6nqy.s[0]++;
(function () {
    'use strict';

    cov_10s8ru6nqy.f[0]++;
    cov_10s8ru6nqy.s[1]++;
    angular.module('app').service('AuthService', service);

    /* @ngInject */
    function service($auth, $rootScope, $state, UsersTable) {
        cov_10s8ru6nqy.f[1]++;

        var AuthService = (cov_10s8ru6nqy.s[2]++, this);
        cov_10s8ru6nqy.s[3]++;
        this.login = function (email, password) {
            cov_10s8ru6nqy.f[2]++;

            var loginPromise = (cov_10s8ru6nqy.s[4]++, $auth.login({ email: email, password: password }));
            cov_10s8ru6nqy.s[5]++;
            loginPromise.then(function () {
                cov_10s8ru6nqy.f[3]++;
                cov_10s8ru6nqy.s[6]++;

                return AuthService.refreshAuthenticatedInfo();
            }, function (error) {
                cov_10s8ru6nqy.f[4]++;
                cov_10s8ru6nqy.s[7]++;

                return error;
            });

            cov_10s8ru6nqy.s[8]++;
            return loginPromise;
        };

        cov_10s8ru6nqy.s[9]++;
        this.loginFacebook = function () {
            cov_10s8ru6nqy.f[5]++;
            cov_10s8ru6nqy.s[10]++;

            return $auth.authenticate('facebook').then(function () {
                cov_10s8ru6nqy.f[6]++;
                cov_10s8ru6nqy.s[11]++;

                return AuthService.refreshAuthenticatedInfo();
            });
        };

        cov_10s8ru6nqy.s[12]++;
        this.refreshAuthenticatedInfo = function () {
            cov_10s8ru6nqy.f[7]++;
            cov_10s8ru6nqy.s[13]++;

            $rootScope.me = UsersTable.$find('me').$then(function () {
                cov_10s8ru6nqy.f[8]++;
                cov_10s8ru6nqy.s[14]++;

                $rootScope.me.$pk = $rootScope.me.id;
                cov_10s8ru6nqy.s[15]++;
                return $rootScope.me;
            });

            cov_10s8ru6nqy.s[16]++;
            return $rootScope.me.$promise;
        };

        cov_10s8ru6nqy.s[17]++;
        this.logout = function () {
            cov_10s8ru6nqy.f[9]++;

            var logoutPromise = (cov_10s8ru6nqy.s[18]++, $auth.logout());
            cov_10s8ru6nqy.s[19]++;
            logoutPromise.then(function () {
                cov_10s8ru6nqy.f[10]++;
                cov_10s8ru6nqy.s[20]++;

                $rootScope.me = false;
                cov_10s8ru6nqy.s[21]++;
                $rootScope.cart = false;
            });
            cov_10s8ru6nqy.s[22]++;
            return logoutPromise;
        };
    }
})();