'use strict';

var cov_hgyvc2s3y = function () {
    var path = '/home/lobo/repos/taskatone-app/src/index.js',
        hash = 'a4601ae0356963468f05812f372c892ac2234d5d',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/index.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 33,
                    column: 19
                }
            },
            '1': {
                start: {
                    line: 38,
                    column: 4
                },
                end: {
                    line: 38,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 40,
                    column: 4
                },
                end: {
                    line: 44,
                    column: 6
                }
            },
            '3': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 99
                }
            },
            '4': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 75
                }
            },
            '5': {
                start: {
                    line: 43,
                    column: 1
                },
                end: {
                    line: 43,
                    column: 89
                }
            },
            '6': {
                start: {
                    line: 46,
                    column: 4
                },
                end: {
                    line: 62,
                    column: 6
                }
            },
            '7': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 60,
                    column: 15
                }
            },
            '9': {
                start: {
                    line: 50,
                    column: 16
                },
                end: {
                    line: 54,
                    column: 17
                }
            },
            '10': {
                start: {
                    line: 51,
                    column: 20
                },
                end: {
                    line: 51,
                    column: 80
                }
            },
            '11': {
                start: {
                    line: 52,
                    column: 23
                },
                end: {
                    line: 54,
                    column: 17
                }
            },
            '12': {
                start: {
                    line: 53,
                    column: 20
                },
                end: {
                    line: 53,
                    column: 56
                }
            },
            '13': {
                start: {
                    line: 55,
                    column: 16
                },
                end: {
                    line: 59,
                    column: 17
                }
            },
            '14': {
                start: {
                    line: 56,
                    column: 20
                },
                end: {
                    line: 58,
                    column: 23
                }
            },
            '15': {
                start: {
                    line: 57,
                    column: 24
                },
                end: {
                    line: 57,
                    column: 93
                }
            },
            '16': {
                start: {
                    line: 64,
                    column: 4
                },
                end: {
                    line: 66,
                    column: 6
                }
            },
            '17': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 41
                }
            },
            '18': {
                start: {
                    line: 68,
                    column: 4
                },
                end: {
                    line: 70,
                    column: 6
                }
            },
            '19': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 42
                }
            },
            '20': {
                start: {
                    line: 72,
                    column: 4
                },
                end: {
                    line: 72,
                    column: 32
                }
            },
            '21': {
                start: {
                    line: 73,
                    column: 4
                },
                end: {
                    line: 76,
                    column: 7
                }
            },
            '22': {
                start: {
                    line: 74,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 68
                }
            },
            '23': {
                start: {
                    line: 74,
                    column: 24
                },
                end: {
                    line: 74,
                    column: 55
                }
            },
            '24': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 40
                }
            },
            '25': {
                start: {
                    line: 82,
                    column: 24
                },
                end: {
                    line: 98,
                    column: 5
                }
            },
            '26': {
                start: {
                    line: 99,
                    column: 4
                },
                end: {
                    line: 101,
                    column: 27
                }
            },
            '27': {
                start: {
                    line: 103,
                    column: 23
                },
                end: {
                    line: 119,
                    column: 5
                }
            },
            '28': {
                start: {
                    line: 120,
                    column: 4
                },
                end: {
                    line: 122,
                    column: 26
                }
            },
            '29': {
                start: {
                    line: 124,
                    column: 21
                },
                end: {
                    line: 139,
                    column: 5
                }
            },
            '30': {
                start: {
                    line: 140,
                    column: 4
                },
                end: {
                    line: 142,
                    column: 24
                }
            },
            '31': {
                start: {
                    line: 145,
                    column: 4
                },
                end: {
                    line: 148,
                    column: 34
                }
            }
        },
        fnMap: {
            '0': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 37,
                        column: 9
                    },
                    end: {
                        line: 37,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 93
                    },
                    end: {
                        line: 77,
                        column: 1
                    }
                },
                line: 37
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 40,
                        column: 32
                    },
                    end: {
                        line: 40,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 44
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 40
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 46,
                        column: 36
                    },
                    end: {
                        line: 46,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 48
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 46
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 47,
                        column: 33
                    },
                    end: {
                        line: 47,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 43
                    },
                    end: {
                        line: 61,
                        column: 9
                    }
                },
                line: 47
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 48,
                        column: 34
                    },
                    end: {
                        line: 48,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 44
                    },
                    end: {
                        line: 60,
                        column: 13
                    }
                },
                line: 48
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 56,
                        column: 34
                    },
                    end: {
                        line: 56,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 44
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                },
                line: 56
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 64,
                        column: 29
                    },
                    end: {
                        line: 64,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 41
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 64
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 68,
                        column: 30
                    },
                    end: {
                        line: 68,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 42
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                line: 68
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 73,
                        column: 27
                    },
                    end: {
                        line: 73,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 73,
                        column: 33
                    },
                    end: {
                        line: 76,
                        column: 5
                    }
                },
                line: 73
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 74,
                        column: 18
                    },
                    end: {
                        line: 74,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 24
                    },
                    end: {
                        line: 74,
                        column: 55
                    }
                },
                line: 74
            },
            '10': {
                name: 'materialTheme',
                decl: {
                    start: {
                        line: 81,
                        column: 9
                    },
                    end: {
                        line: 81,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 43
                    },
                    end: {
                        line: 149,
                        column: 1
                    }
                },
                line: 81
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                }, {
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                }],
                line: 50
            },
            '1': {
                loc: {
                    start: {
                        line: 50,
                        column: 20
                    },
                    end: {
                        line: 50,
                        column: 73
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 50,
                        column: 20
                    },
                    end: {
                        line: 50,
                        column: 35
                    }
                }, {
                    start: {
                        line: 50,
                        column: 39
                    },
                    end: {
                        line: 50,
                        column: 73
                    }
                }],
                line: 50
            },
            '2': {
                loc: {
                    start: {
                        line: 52,
                        column: 23
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 52,
                        column: 23
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                }, {
                    start: {
                        line: 52,
                        column: 23
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                }],
                line: 52
            },
            '3': {
                loc: {
                    start: {
                        line: 55,
                        column: 16
                    },
                    end: {
                        line: 59,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 55,
                        column: 16
                    },
                    end: {
                        line: 59,
                        column: 17
                    }
                }, {
                    start: {
                        line: 55,
                        column: 16
                    },
                    end: {
                        line: 59,
                        column: 17
                    }
                }],
                line: 55
            },
            '4': {
                loc: {
                    start: {
                        line: 55,
                        column: 20
                    },
                    end: {
                        line: 55,
                        column: 59
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 55,
                        column: 20
                    },
                    end: {
                        line: 55,
                        column: 27
                    }
                }, {
                    start: {
                        line: 55,
                        column: 31
                    },
                    end: {
                        line: 55,
                        column: 59
                    }
                }],
                line: 55
            },
            '5': {
                loc: {
                    start: {
                        line: 57,
                        column: 50
                    },
                    end: {
                        line: 57,
                        column: 92
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 57,
                        column: 80
                    },
                    end: {
                        line: 57,
                        column: 84
                    }
                }, {
                    start: {
                        line: 57,
                        column: 87
                    },
                    end: {
                        line: 57,
                        column: 92
                    }
                }],
                line: 57
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_hgyvc2s3y.s[0]++;
angular.module('app', ['ui.router', 'restmod', 'restmod.styles.drfPaged', 'ngMaterial', 'satellizer', 'ngFileUpload', 'cgBusy', 'angularMoment', 'angular-loading-bar', 'timer', 'ui.mask', 'angular.filter', 'mdPickers', 'angular-click-outside']).constant('moment', moment).constant('AppSettings', {
    appInProduction: false,
    appUrl: 'https://taskatone.soloweb.pt',
    apiUrl: 'https://api.taskatone.soloweb.pt',
    mediaUrl: 'https://api.taskatone.soloweb.pt/static/media'
}).value('cgBusyDefaults', {
    message: '',
    templateUrl: 'app/shared/html/cgbusy/cgbusy.template.html'
}).config(materialTheme)
/*.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
 cfpLoadingBarProvider.latencyThreshold = 10;
 }])*/
.run(runBlock);

/** @ngInject */
function runBlock($rootScope, AppSettings, UsersTable, ProjectsTable, $interval, $mdSidenav) {
    cov_hgyvc2s3y.f[0]++;
    cov_hgyvc2s3y.s[1]++;

    $rootScope.AppSettings = AppSettings;

    cov_hgyvc2s3y.s[2]++;
    $rootScope.loadStaticData = function () {
        cov_hgyvc2s3y.f[1]++;
        cov_hgyvc2s3y.s[3]++;

        $rootScope.users = UsersTable.$all = UsersTable.$search({ type: 'EMPLOYEE', on_leave: "0" });
        cov_hgyvc2s3y.s[4]++;
        $rootScope.projects = ProjectsTable.$all = ProjectsTable.$search();
        cov_hgyvc2s3y.s[5]++;
        $rootScope.activeProjects = ProjectsTable.$search({ ordering: 'name', state: 'CREATED' });
    };

    cov_hgyvc2s3y.s[6]++;
    $rootScope.refreshDynamicData = function () {
        cov_hgyvc2s3y.f[2]++;
        cov_hgyvc2s3y.s[7]++;

        $rootScope.users.forEach(function (user) {
            cov_hgyvc2s3y.f[3]++;
            cov_hgyvc2s3y.s[8]++;

            user.$refresh().$then(function (user) {
                cov_hgyvc2s3y.f[4]++;

                var request;
                cov_hgyvc2s3y.s[9]++;
                if ((cov_hgyvc2s3y.b[1][0]++, !user.$lastTask) || (cov_hgyvc2s3y.b[1][1]++, user.lastTask != user.$lastTask.id)) {
                    cov_hgyvc2s3y.b[0][0]++;
                    cov_hgyvc2s3y.s[10]++;

                    request = user.$load('lastTask').$lastTask.$load('project');
                } else {
                        cov_hgyvc2s3y.b[0][1]++;
                        cov_hgyvc2s3y.s[11]++;
                        if (user.$lastTask.$pk) {
                            cov_hgyvc2s3y.b[2][0]++;
                            cov_hgyvc2s3y.s[12]++;

                            request = user.$lastTask.$refresh();
                        } else {
                            cov_hgyvc2s3y.b[2][1]++;
                        }
                    }cov_hgyvc2s3y.s[13]++;
                if ((cov_hgyvc2s3y.b[4][0]++, request) && (cov_hgyvc2s3y.b[4][1]++, $rootScope.me.id === user.id)) {
                    cov_hgyvc2s3y.b[3][0]++;
                    cov_hgyvc2s3y.s[14]++;

                    request.$then(function (task) {
                        cov_hgyvc2s3y.f[5]++;
                        cov_hgyvc2s3y.s[15]++;

                        $rootScope.me.isWorking = task.state == 'EXECUTING' ? (cov_hgyvc2s3y.b[5][0]++, task) : (cov_hgyvc2s3y.b[5][1]++, false);
                    });
                } else {
                    cov_hgyvc2s3y.b[3][1]++;
                }
            });
        });
    };

    cov_hgyvc2s3y.s[16]++;
    $rootScope.openSidebar = function () {
        cov_hgyvc2s3y.f[6]++;
        cov_hgyvc2s3y.s[17]++;

        return $mdSidenav('menu').open();
    };

    cov_hgyvc2s3y.s[18]++;
    $rootScope.closeSidebar = function () {
        cov_hgyvc2s3y.f[7]++;
        cov_hgyvc2s3y.s[19]++;

        return $mdSidenav('menu').close();
    };

    cov_hgyvc2s3y.s[20]++;
    $rootScope.loadStaticData();
    cov_hgyvc2s3y.s[21]++;
    $rootScope.users.$then(function () {
        cov_hgyvc2s3y.f[8]++;
        cov_hgyvc2s3y.s[22]++;

        $interval(function () {
            cov_hgyvc2s3y.f[9]++;
            cov_hgyvc2s3y.s[23]++;
            return $rootScope.refreshDynamicData();
        }, 1000 * 10);
        cov_hgyvc2s3y.s[24]++;
        $rootScope.refreshDynamicData();
    });
}

/** @ngInject */
function materialTheme($mdThemingProvider) {
    cov_hgyvc2s3y.f[10]++;

    var customPrimary = (cov_hgyvc2s3y.s[25]++, {
        '50': '#626262',
        '100': '#555555',
        '200': '#484848',
        '300': '#3b3b3b',
        '400': '#2f2f2f',
        '500': '#222222',
        '600': '#151515',
        '700': '#080808',
        '800': '#000000',
        '900': '#000000',
        'A100': '#6e6e6e',
        'A200': '#7b7b7b',
        'A400': '#888888',
        'A700': '#000000',
        'contrastDefaultColor': 'light'
    });
    cov_hgyvc2s3y.s[26]++;
    $mdThemingProvider.definePalette('customPrimary', customPrimary);

    var customAccent = (cov_hgyvc2s3y.s[27]++, {
        '50': '#171c03',
        '100': '#2a3306',
        '200': '#3d4a08',
        '300': '#4f610b',
        '400': '#62780d',
        '500': '#758f10',
        '600': '#9bbd14',
        '700': '#aed417',
        '800': '#bee71e',
        '900': '#c5e935',
        'A100': '#9bbd14',
        'A200': '#88A612',
        'A400': '#758f10',
        'A700': '#cbec4c',
        'contrastDefaultColor': 'light'
    });
    cov_hgyvc2s3y.s[28]++;
    $mdThemingProvider.definePalette('customAccent', customAccent);

    var customWarn = (cov_hgyvc2s3y.s[29]++, {
        '50': '#ffb280',
        '100': '#ffa266',
        '200': '#ff934d',
        '300': '#ff8333',
        '400': '#ff741a',
        '500': '#ff6400',
        '600': '#e65a00',
        '700': '#cc5000',
        '800': '#b34600',
        '900': '#993c00',
        'A100': '#ffc199',
        'A200': '#ffd1b3',
        'A400': '#ffe0cc',
        'A700': '#803200'
    });
    cov_hgyvc2s3y.s[30]++;
    $mdThemingProvider.definePalette('customWarn', customWarn);

    cov_hgyvc2s3y.s[31]++;
    $mdThemingProvider.theme('default').primaryPalette('customPrimary').accentPalette('customAccent').warnPalette('customWarn');
}