'use strict';

var cov_25fudiiq64 = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/statisticsList/statisticsList.js',
        hash = '7f2b386272a941818538689406ef9b024b072069',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/statisticsList/statisticsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 10
                }
            },
            '1': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 33
                }
            },
            '2': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 23,
                    column: 0
                },
                end: {
                    line: 31,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 23
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 14
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* eslint-disable no-trailing-spaces,indent */
var StatisticsListController = function () {
    function StatisticsListController() {
        _classCallCheck(this, StatisticsListController);

        cov_25fudiiq64.f[0]++;
        cov_25fudiiq64.s[0]++;

        this.groups = [{ id: 'PROJECT', name: 'Projects' }, { id: 'USER', name: 'Users' }];
    }

    _createClass(StatisticsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_25fudiiq64.f[1]++;
            cov_25fudiiq64.s[1]++;

            this.groupBy = "PROJECT";
        }
    }, {
        key: 'updateStatistics',
        value: function updateStatistics() {
            cov_25fudiiq64.f[2]++;
            cov_25fudiiq64.s[2]++;

            this.statistics.$refresh({
                start_date__gt: this.startDate,
                end_date__lt: this.endDate,
                group_by: this.groupBy
            });
        }
    }]);

    return StatisticsListController;
}();

cov_25fudiiq64.s[3]++;


angular.module('app').component('statisticsList', {
    templateUrl: 'app/components/statisticsList/statisticsList.html',
    controller: StatisticsListController,
    bindings: {
        statistics: '<'
    }
});