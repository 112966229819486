'use strict';

var cov_vltpuh6bu = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/users/usersList/usersList.js',
        hash = '2ae75e322a06ce527ad9605f6910bf5007fdb604',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/users/usersList/usersList.js',
        statementMap: {
            '0': {
                start: {
                    line: 7,
                    column: 0
                },
                end: {
                    line: 16,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            }
        },
        branchMap: {},
        s: {
            '0': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UsersListController = function UsersListController() {
    _classCallCheck(this, UsersListController);

    cov_vltpuh6bu.f[0]++;
};

cov_vltpuh6bu.s[0]++;


angular.module('app').component('usersList', {
    templateUrl: 'app/components/users/usersList/usersList.html',
    controller: UsersListController,
    bindings: {
        members: '<',
        accountants: '<'
    }
});