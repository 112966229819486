'use strict';

var cov_2p061xr9q4 = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/html/deliveryInput/deliveryInput.js',
        hash = '4f6a49148378d64526500ad819a4b40045a0b896',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/html/deliveryInput/deliveryInput.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 32
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 56
                }
            },
            '3': {
                start: {
                    line: 15,
                    column: 0
                },
                end: {
                    line: 24,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 26
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 4
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 15
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DeliveryInputController = function () {
    function DeliveryInputController($element) {
        _classCallCheck(this, DeliveryInputController);

        cov_2p061xr9q4.f[0]++;
        cov_2p061xr9q4.s[0]++;

        this.element = $element;
    }

    _createClass(DeliveryInputController, [{
        key: 'changed',
        value: function changed() {
            cov_2p061xr9q4.f[1]++;
            cov_2p061xr9q4.s[1]++;

            this.ngChange({ $delivery: this.ngModel });
        }
    }, {
        key: 'setFocus',
        value: function setFocus() {
            cov_2p061xr9q4.f[2]++;
            cov_2p061xr9q4.s[2]++;

            this.element[0].children[0].children[1].focus();
        }
    }]);

    return DeliveryInputController;
}();

cov_2p061xr9q4.s[3]++;


angular.module('app').component('deliveryInput', {
    templateUrl: 'app/shared/html/deliveryInput/deliveryInput.html',
    controller: DeliveryInputController,
    bindings: {
        ngChange: '&',
        ngModel: '='
    }
});