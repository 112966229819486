'use strict';

var cov_d8312igoc = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.tasks.factory.js',
        hash = 'b2bbb7d55fe0d2810da4dfe71421be3f72dc423f',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.tasks.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 88,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 43
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 86,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 19,
                    column: 20
                },
                end: {
                    line: 22,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 20,
                    column: 24
                },
                end: {
                    line: 20,
                    column: 47
                }
            },
            '5': {
                start: {
                    line: 21,
                    column: 20
                },
                end: {
                    line: 21,
                    column: 52
                }
            },
            '6': {
                start: {
                    line: 61,
                    column: 20
                },
                end: {
                    line: 61,
                    column: 43
                }
            },
            '7': {
                start: {
                    line: 66,
                    column: 20
                },
                end: {
                    line: 66,
                    column: 43
                }
            },
            '8': {
                start: {
                    line: 71,
                    column: 20
                },
                end: {
                    line: 71,
                    column: 43
                }
            },
            '9': {
                start: {
                    line: 76,
                    column: 30
                },
                end: {
                    line: 76,
                    column: 40
                }
            },
            '10': {
                start: {
                    line: 77,
                    column: 20
                },
                end: {
                    line: 77,
                    column: 31
                }
            },
            '11': {
                start: {
                    line: 82,
                    column: 30
                },
                end: {
                    line: 82,
                    column: 40
                }
            },
            '12': {
                start: {
                    line: 83,
                    column: 20
                },
                end: {
                    line: 83,
                    column: 31
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 88,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 38
                    },
                    end: {
                        line: 87,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 18,
                        column: 24
                    },
                    end: {
                        line: 18,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 41
                    },
                    end: {
                        line: 23,
                        column: 17
                    }
                },
                line: 18
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 60,
                        column: 24
                    },
                    end: {
                        line: 60,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 41
                    },
                    end: {
                        line: 62,
                        column: 17
                    }
                },
                line: 60
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 65,
                        column: 24
                    },
                    end: {
                        line: 65,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 41
                    },
                    end: {
                        line: 67,
                        column: 17
                    }
                },
                line: 65
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 70,
                        column: 24
                    },
                    end: {
                        line: 70,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 41
                    },
                    end: {
                        line: 72,
                        column: 17
                    }
                },
                line: 70
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 75,
                        column: 24
                    },
                    end: {
                        line: 75,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 75,
                        column: 41
                    },
                    end: {
                        line: 78,
                        column: 17
                    }
                },
                line: 75
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 81,
                        column: 24
                    },
                    end: {
                        line: 81,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 41
                    },
                    end: {
                        line: 84,
                        column: 17
                    }
                },
                line: 81
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 19,
                        column: 20
                    },
                    end: {
                        line: 22,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 20
                    },
                    end: {
                        line: 22,
                        column: 21
                    }
                }, {
                    start: {
                        line: 19,
                        column: 20
                    },
                    end: {
                        line: 22,
                        column: 21
                    }
                }],
                line: 19
            },
            '1': {
                loc: {
                    start: {
                        line: 76,
                        column: 30
                    },
                    end: {
                        line: 76,
                        column: 40
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 76,
                        column: 30
                    },
                    end: {
                        line: 76,
                        column: 35
                    }
                }, {
                    start: {
                        line: 76,
                        column: 39
                    },
                    end: {
                        line: 76,
                        column: 40
                    }
                }],
                line: 76
            },
            '2': {
                loc: {
                    start: {
                        line: 82,
                        column: 30
                    },
                    end: {
                        line: 82,
                        column: 40
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 82,
                        column: 30
                    },
                    end: {
                        line: 82,
                        column: 35
                    }
                }, {
                    start: {
                        line: 82,
                        column: 39
                    },
                    end: {
                        line: 82,
                        column: 40
                    }
                }],
                line: 82
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_d8312igoc.s[0]++;
(function () {
    'use strict';

    cov_d8312igoc.f[0]++;
    cov_d8312igoc.s[1]++;
    angular.module('app').factory('TasksTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_d8312igoc.f[1]++;
        cov_d8312igoc.s[2]++;


        return restmod.model('/tasks').mix('MixinContainable', {
            $extend: {
                Collection: {
                    $limit: 1000
                }
            },
            dueDate: {
                decode: function decode(value) {
                    cov_d8312igoc.f[2]++;
                    cov_d8312igoc.s[3]++;

                    if (value !== null) {
                        cov_d8312igoc.b[0][0]++;
                        cov_d8312igoc.s[4]++;

                        return new Date(value);
                        cov_d8312igoc.s[5]++;
                        return newDate(value).getDate();
                    } else {
                        cov_d8312igoc.b[0][1]++;
                    }
                }
            },
            $config: {
                contains: {
                    executions: {
                        type: 'hasMany',
                        table: 'ExecutionsTable',
                        foreignKey: 'task'
                    },
                    user: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'user'
                    },
                    project: {
                        type: 'belongsTo',
                        table: 'ProjectsTable',
                        foreignKey: 'project'
                    },
                    deliverables: {
                        type: 'hasMany',
                        table: 'DeliverablesTable',
                        foreignKey: 'task'
                    },
                    watchers: {
                        type: 'hasMany',
                        table: 'WatchersTable',
                        foreignKey: 'task'
                    },
                    comments: {
                        type: 'hasMany',
                        table: 'CommentsTable',
                        foreignKey: 'objectId'
                    }
                }
            },
            updated: {
                decode: function decode(value) {
                    cov_d8312igoc.f[3]++;
                    cov_d8312igoc.s[6]++;

                    return new Date(value);
                }
            },
            workingSince: {
                decode: function decode(value) {
                    cov_d8312igoc.f[4]++;
                    cov_d8312igoc.s[7]++;

                    return new Date(value);
                }
            },
            stoppedSince: {
                decode: function decode(value) {
                    cov_d8312igoc.f[5]++;
                    cov_d8312igoc.s[8]++;

                    return new Date(value);
                }
            },
            totalDuration: {
                decode: function decode(value) {
                    cov_d8312igoc.f[6]++;

                    var num = (cov_d8312igoc.s[9]++, (cov_d8312igoc.b[1][0]++, value) || (cov_d8312igoc.b[1][1]++, 0));
                    cov_d8312igoc.s[10]++;
                    return num;
                }
            },
            eta: {
                decode: function decode(value) {
                    cov_d8312igoc.f[7]++;

                    var num = (cov_d8312igoc.s[11]++, (cov_d8312igoc.b[2][0]++, value) || (cov_d8312igoc.b[2][1]++, 0));
                    cov_d8312igoc.s[12]++;
                    return num;
                }
            }
        });
    }
})();