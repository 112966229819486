'use strict';

var cov_iq0pcmyni = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/taskView/taskView.js',
        hash = 'a43499378096b02713aeafa647dd260df3a226d3',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/taskView/taskView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 43
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 49
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 0
                },
                end: {
                    line: 21,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 44
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 15
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TaskViewController = function () {
    function TaskViewController(UsersTable, ProjectsTable) {
        _classCallCheck(this, TaskViewController);

        cov_iq0pcmyni.f[0]++;
        cov_iq0pcmyni.s[0]++;

        this.users = UsersTable.$all;
        cov_iq0pcmyni.s[1]++;
        this.projects = ProjectsTable.$all;
    }

    _createClass(TaskViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_iq0pcmyni.f[1]++;
            cov_iq0pcmyni.s[2]++;

            this.task.$load('project').$load('user');
        }
    }]);

    return TaskViewController;
}();

cov_iq0pcmyni.s[3]++;


angular.module('app').component('taskView', {
    templateUrl: 'app/components/taskView/taskView.html',
    controller: TaskViewController,
    bindings: {
        task: '<'
    }
});