'use strict';

var cov_1vruesktun = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/taskPreview/taskPreview.js',
        hash = '2cd1f53215f174edf062cfbb5c9e3447bc7b91b3',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/taskPreview/taskPreview.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 62
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 74
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 65
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 16
                },
                end: {
                    line: 10,
                    column: 37
                }
            },
            '7': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 37
                }
            },
            '10': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 84
                }
            },
            '11': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 11
                }
            },
            '12': {
                start: {
                    line: 26,
                    column: 126
                },
                end: {
                    line: 26,
                    column: 148
                }
            },
            '13': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 32
                }
            },
            '14': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 31
                }
            },
            '15': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 11
                }
            },
            '16': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 74
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 32
                }
            },
            '18': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 9
                }
            },
            '19': {
                start: {
                    line: 44,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 48
                }
            },
            '20': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 11
                }
            },
            '21': {
                start: {
                    line: 47,
                    column: 12
                },
                end: {
                    line: 47,
                    column: 74
                }
            },
            '22': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 32
                }
            },
            '23': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 29
                }
            },
            '24': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 45
                }
            },
            '25': {
                start: {
                    line: 58,
                    column: 19
                },
                end: {
                    line: 58,
                    column: 24
                }
            },
            '26': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 9
                }
            },
            '27': {
                start: {
                    line: 60,
                    column: 12
                },
                end: {
                    line: 62,
                    column: 13
                }
            },
            '28': {
                start: {
                    line: 61,
                    column: 16
                },
                end: {
                    line: 61,
                    column: 28
                }
            },
            '29': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 20
                }
            },
            '30': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 28
                }
            },
            '31': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 11
                }
            },
            '32': {
                start: {
                    line: 70,
                    column: 12
                },
                end: {
                    line: 70,
                    column: 32
                }
            },
            '33': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 26
                }
            },
            '34': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 11
                }
            },
            '35': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 77,
                    column: 32
                }
            },
            '36': {
                start: {
                    line: 82,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 58
                }
            },
            '37': {
                start: {
                    line: 83,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 11
                }
            },
            '38': {
                start: {
                    line: 84,
                    column: 11
                },
                end: {
                    line: 84,
                    column: 49
                }
            },
            '39': {
                start: {
                    line: 89,
                    column: 0
                },
                end: {
                    line: 118,
                    column: 3
                }
            },
            '40': {
                start: {
                    line: 101,
                    column: 4
                },
                end: {
                    line: 117,
                    column: 5
                }
            },
            '41': {
                start: {
                    line: 105,
                    column: 12
                },
                end: {
                    line: 115,
                    column: 14
                }
            },
            '42': {
                start: {
                    line: 106,
                    column: 35
                },
                end: {
                    line: 106,
                    column: 42
                }
            },
            '43': {
                start: {
                    line: 107,
                    column: 16
                },
                end: {
                    line: 109,
                    column: 17
                }
            },
            '44': {
                start: {
                    line: 108,
                    column: 20
                },
                end: {
                    line: 108,
                    column: 57
                }
            },
            '45': {
                start: {
                    line: 110,
                    column: 16
                },
                end: {
                    line: 110,
                    column: 58
                }
            },
            '46': {
                start: {
                    line: 112,
                    column: 16
                },
                end: {
                    line: 114,
                    column: 17
                }
            },
            '47': {
                start: {
                    line: 113,
                    column: 20
                },
                end: {
                    line: 113,
                    column: 36
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 101
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 35
                    },
                    end: {
                        line: 8,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 52
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 35
                    },
                    end: {
                        line: 14,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 52
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 14
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 23
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                line: 25
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 26,
                        column: 112
                    },
                    end: {
                        line: 26,
                        column: 113
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 126
                    },
                    end: {
                        line: 26,
                        column: 148
                    }
                },
                line: 26
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 26,
                        column: 156
                    },
                    end: {
                        line: 26,
                        column: 157
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 162
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                line: 26
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 16
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 31
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 19
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 35
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 36,
                        column: 47
                    },
                    end: {
                        line: 36,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 53
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                line: 36
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 31
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 42
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 46,
                        column: 46
                    },
                    end: {
                        line: 46,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 52
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 46
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 52,
                        column: 4
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 19
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 52
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 16
                    },
                    end: {
                        line: 65,
                        column: 5
                    }
                },
                line: 57
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 67,
                        column: 4
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 14
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 67
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 69,
                        column: 32
                    },
                    end: {
                        line: 69,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 38
                    },
                    end: {
                        line: 71,
                        column: 9
                    }
                },
                line: 69
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 16
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                line: 74
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 76,
                        column: 32
                    },
                    end: {
                        line: 76,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 38
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                },
                line: 76
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 81,
                        column: 4
                    },
                    end: {
                        line: 81,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 32
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                line: 81
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 83,
                        column: 54
                    },
                    end: {
                        line: 83,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 69
                    },
                    end: {
                        line: 85,
                        column: 9
                    }
                },
                line: 83
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 100,
                        column: 31
                    },
                    end: {
                        line: 100,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 100,
                        column: 43
                    },
                    end: {
                        line: 118,
                        column: 1
                    }
                },
                line: 100
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 104,
                        column: 14
                    },
                    end: {
                        line: 104,
                        column: 15
                    }
                },
                loc: {
                    start: {
                        line: 104,
                        column: 52
                    },
                    end: {
                        line: 116,
                        column: 9
                    }
                },
                line: 104
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 105,
                        column: 25
                    },
                    end: {
                        line: 105,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 105,
                        column: 37
                    },
                    end: {
                        line: 111,
                        column: 13
                    }
                },
                line: 105
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 111,
                        column: 15
                    },
                    end: {
                        line: 111,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 111,
                        column: 33
                    },
                    end: {
                        line: 115,
                        column: 13
                    }
                },
                line: 111
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }, {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }],
                line: 9
            },
            '1': {
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 15
            },
            '2': {
                loc: {
                    start: {
                        line: 42,
                        column: 16
                    },
                    end: {
                        line: 42,
                        column: 29
                    }
                },
                type: 'default-arg',
                locations: [{
                    start: {
                        line: 42,
                        column: 24
                    },
                    end: {
                        line: 42,
                        column: 29
                    }
                }],
                line: 42
            },
            '3': {
                loc: {
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                }, {
                    start: {
                        line: 43,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                }],
                line: 43
            },
            '4': {
                loc: {
                    start: {
                        line: 60,
                        column: 12
                    },
                    end: {
                        line: 62,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 60,
                        column: 12
                    },
                    end: {
                        line: 62,
                        column: 13
                    }
                }, {
                    start: {
                        line: 60,
                        column: 12
                    },
                    end: {
                        line: 62,
                        column: 13
                    }
                }],
                line: 60
            },
            '5': {
                loc: {
                    start: {
                        line: 112,
                        column: 16
                    },
                    end: {
                        line: 114,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 112,
                        column: 16
                    },
                    end: {
                        line: 114,
                        column: 17
                    }
                }, {
                    start: {
                        line: 112,
                        column: 16
                    },
                    end: {
                        line: 114,
                        column: 17
                    }
                }],
                line: 112
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TaskPreviewController = function () {
    function TaskPreviewController(StartWorkingTable, StopWorkingService, $rootScope, $scope, NewExecutionModalService) {
        var _this = this;

        _classCallCheck(this, TaskPreviewController);

        cov_1vruesktun.f[0]++;
        cov_1vruesktun.s[0]++;

        this.startWorkingService = StartWorkingTable.$build();
        cov_1vruesktun.s[1]++;
        this.stopWorkingService = StopWorkingService.$build({ close: '0' });
        cov_1vruesktun.s[2]++;
        this.$rootScope = $rootScope;
        cov_1vruesktun.s[3]++;
        this.NewExecutionModalService = NewExecutionModalService;

        cov_1vruesktun.s[4]++;
        $scope.$on('task-stopped', function (event, args) {
            cov_1vruesktun.f[1]++;
            cov_1vruesktun.s[5]++;

            if (args.task.id == _this.task.id) {
                cov_1vruesktun.b[0][0]++;
                cov_1vruesktun.s[6]++;

                _this.task.$refresh();
            } else {
                cov_1vruesktun.b[0][1]++;
            }
        });

        cov_1vruesktun.s[7]++;
        $scope.$on('task-started', function (event, args) {
            cov_1vruesktun.f[2]++;
            cov_1vruesktun.s[8]++;

            if (_this.task.state == 'EXECUTING') {
                cov_1vruesktun.b[1][0]++;
                cov_1vruesktun.s[9]++;

                _this.task.$refresh();
            } else {
                cov_1vruesktun.b[1][1]++;
            }
        });
    }

    _createClass(TaskPreviewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1vruesktun.f[3]++;
            cov_1vruesktun.s[10]++;

            this.startWorkingService.task = this.stopWorkingService.task = this.task.id;
        }
    }, {
        key: 'loadDetailedInfo',
        value: function loadDetailedInfo() {
            var _this2 = this;

            cov_1vruesktun.f[4]++;
            cov_1vruesktun.s[11]++;

            this.task.$load('deliverables').$load('watchers').$load('comments').$load('executions').$comments.$then(function (comments) {
                cov_1vruesktun.f[5]++;
                cov_1vruesktun.s[12]++;
                return comments.$load('user');
            }).$then(function () {
                cov_1vruesktun.f[6]++;
                cov_1vruesktun.s[13]++;

                _this2._refreshData();
            });
        }
    }, {
        key: 'closeTask',
        value: function closeTask() {
            cov_1vruesktun.f[7]++;
            cov_1vruesktun.s[14]++;

            this.stopWorking(true);
        }
    }, {
        key: 'startWorking',
        value: function startWorking() {
            var _this3 = this;

            cov_1vruesktun.f[8]++;
            cov_1vruesktun.s[15]++;

            this.startWorkingService.$save().$then(function () {
                cov_1vruesktun.f[9]++;
                cov_1vruesktun.s[16]++;

                _this3.$rootScope.$broadcast('task-started', { task: _this3.task });
                cov_1vruesktun.s[17]++;
                _this3._refreshData();
            });
        }
    }, {
        key: 'stopWorking',
        value: function stopWorking() {
            var _this4 = this;

            var close = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_1vruesktun.b[2][0]++, false);
            cov_1vruesktun.f[10]++;
            cov_1vruesktun.s[18]++;

            if (close) {
                cov_1vruesktun.b[3][0]++;
                cov_1vruesktun.s[19]++;

                this.stopWorkingService.close = '1';
            } else {
                cov_1vruesktun.b[3][1]++;
            }
            cov_1vruesktun.s[20]++;
            this.stopWorkingService.$save().$then(function () {
                cov_1vruesktun.f[11]++;
                cov_1vruesktun.s[21]++;

                _this4.$rootScope.$broadcast('task-stopped', { task: _this4.task });
                cov_1vruesktun.s[22]++;
                _this4._refreshData();
            });
        }
    }, {
        key: '_refreshData',
        value: function _refreshData() {
            cov_1vruesktun.f[12]++;
            cov_1vruesktun.s[23]++;

            this.task.$refresh();
            cov_1vruesktun.s[24]++;
            this.$rootScope.refreshDynamicData();
        }
    }, {
        key: 'isWatcher',
        value: function isWatcher() {
            cov_1vruesktun.f[13]++;

            var flag = (cov_1vruesktun.s[25]++, false);
            cov_1vruesktun.s[26]++;
            for (var i = 0; i < this.task.$watchers.length; i++) {
                cov_1vruesktun.s[27]++;

                if (this.$rootScope.me.id === this.task.$watchers[i].user) {
                    cov_1vruesktun.b[4][0]++;
                    cov_1vruesktun.s[28]++;

                    flag = true;
                } else {
                    cov_1vruesktun.b[4][1]++;
                }
            }
            cov_1vruesktun.s[29]++;
            return flag;
        }
    }, {
        key: 'pinTask',
        value: function pinTask() {
            var _this5 = this;

            cov_1vruesktun.f[14]++;
            cov_1vruesktun.s[30]++;

            this.task.order = 0;
            cov_1vruesktun.s[31]++;
            this.task.$save().$then(function () {
                cov_1vruesktun.f[15]++;
                cov_1vruesktun.s[32]++;

                _this5._refreshData();
            });
        }
    }, {
        key: 'unpinTask',
        value: function unpinTask() {
            var _this6 = this;

            cov_1vruesktun.f[16]++;
            cov_1vruesktun.s[33]++;

            this.task.order++;
            cov_1vruesktun.s[34]++;
            this.task.$save().$then(function () {
                cov_1vruesktun.f[17]++;
                cov_1vruesktun.s[35]++;

                _this6._refreshData();
            });
        }
    }, {
        key: 'displayNewExecutionModal',
        value: function displayNewExecutionModal() {
            var _this7 = this;

            cov_1vruesktun.f[18]++;
            cov_1vruesktun.s[36]++;

            this.NewExecutionModalService.task = this.task.id;
            cov_1vruesktun.s[37]++;
            this.NewExecutionModalService.display().$then(function (execution) {
                cov_1vruesktun.f[19]++;
                cov_1vruesktun.s[38]++;

                _this7.task.$executions.$add(execution);
            });
        }
    }]);

    return TaskPreviewController;
}();

cov_1vruesktun.s[39]++;


angular.module('app').component('taskPreview', {
    templateUrl: 'app/components/taskPreview/taskPreview.html',
    controller: TaskPreviewController,
    bindings: {
        task: '<',
        isOpen: '=',
        displayMinimal: '<'
    }
}).directive('forceSelectFocus', function () {
    cov_1vruesktun.f[20]++;
    cov_1vruesktun.s[40]++;

    return {
        restrict: 'A',
        require: ['^^mdSelect', '^ngModel'],
        link: function link(scope, element, controller) {
            cov_1vruesktun.f[21]++;
            cov_1vruesktun.s[41]++;

            scope.$watch(function () {
                cov_1vruesktun.f[22]++;

                var foundElement = (cov_1vruesktun.s[42]++, element);
                cov_1vruesktun.s[43]++;
                while (!foundElement.hasClass('md-select-menu-container')) {
                    cov_1vruesktun.s[44]++;

                    foundElement = foundElement.parent();
                }
                cov_1vruesktun.s[45]++;
                return foundElement.hasClass('md-active');
            }, function (newVal) {
                cov_1vruesktun.f[23]++;
                cov_1vruesktun.s[46]++;

                if (newVal) {
                    cov_1vruesktun.b[5][0]++;
                    cov_1vruesktun.s[47]++;

                    element.focus();
                } else {
                    cov_1vruesktun.b[5][1]++;
                }
            });
        }
    };
});