'use strict';

var cov_29w7p51sxp = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/projectStories/projectStories.js',
        hash = '6a53c335e23a1c182dcfab038e32491be1acc898',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/projectStories/projectStories.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 31
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 30
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 25
                }
            },
            '4': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 24
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 47
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 39
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 22
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 110
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 36
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 11
                }
            },
            '11': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 66
                }
            },
            '12': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 51
                }
            },
            '13': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 51
                }
            },
            '14': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 22
                }
            },
            '15': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 64
                }
            },
            '16': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 65
                }
            },
            '17': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 9
                }
            },
            '18': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 44
                }
            },
            '19': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 20
                }
            },
            '20': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 75
                }
            },
            '21': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 61
                }
            },
            '22': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 9
                }
            },
            '23': {
                start: {
                    line: 45,
                    column: 12
                },
                end: {
                    line: 45,
                    column: 60
                }
            },
            '24': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 18
                }
            },
            '25': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 43
                }
            },
            '26': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 49
                }
            },
            '27': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 9
                }
            },
            '28': {
                start: {
                    line: 57,
                    column: 12
                },
                end: {
                    line: 57,
                    column: 37
                }
            },
            '29': {
                start: {
                    line: 60,
                    column: 12
                },
                end: {
                    line: 62,
                    column: 15
                }
            },
            '30': {
                start: {
                    line: 61,
                    column: 16
                },
                end: {
                    line: 61,
                    column: 50
                }
            },
            '31': {
                start: {
                    line: 67,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 9
                }
            },
            '32': {
                start: {
                    line: 68,
                    column: 12
                },
                end: {
                    line: 68,
                    column: 49
                }
            },
            '33': {
                start: {
                    line: 70,
                    column: 8
                },
                end: {
                    line: 72,
                    column: 11
                }
            },
            '34': {
                start: {
                    line: 71,
                    column: 12
                },
                end: {
                    line: 71,
                    column: 127
                }
            },
            '35': {
                start: {
                    line: 76,
                    column: 20
                },
                end: {
                    line: 76,
                    column: 35
                }
            },
            '36': {
                start: {
                    line: 77,
                    column: 20
                },
                end: {
                    line: 77,
                    column: 22
                }
            },
            '37': {
                start: {
                    line: 78,
                    column: 21
                },
                end: {
                    line: 78,
                    column: 45
                }
            },
            '38': {
                start: {
                    line: 79,
                    column: 8
                },
                end: {
                    line: 92,
                    column: 9
                }
            },
            '39': {
                start: {
                    line: 80,
                    column: 12
                },
                end: {
                    line: 80,
                    column: 34
                }
            },
            '40': {
                start: {
                    line: 81,
                    column: 28
                },
                end: {
                    line: 81,
                    column: 32
                }
            },
            '41': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 88,
                    column: 13
                }
            },
            '42': {
                start: {
                    line: 83,
                    column: 16
                },
                end: {
                    line: 83,
                    column: 43
                }
            },
            '43': {
                start: {
                    line: 84,
                    column: 16
                },
                end: {
                    line: 87,
                    column: 17
                }
            },
            '44': {
                start: {
                    line: 85,
                    column: 20
                },
                end: {
                    line: 85,
                    column: 38
                }
            },
            '45': {
                start: {
                    line: 86,
                    column: 20
                },
                end: {
                    line: 86,
                    column: 26
                }
            },
            '46': {
                start: {
                    line: 89,
                    column: 12
                },
                end: {
                    line: 91,
                    column: 13
                }
            },
            '47': {
                start: {
                    line: 90,
                    column: 16
                },
                end: {
                    line: 90,
                    column: 37
                }
            },
            '48': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 21
                }
            },
            '49': {
                start: {
                    line: 97,
                    column: 0
                },
                end: {
                    line: 143,
                    column: 7
                }
            },
            '50': {
                start: {
                    line: 108,
                    column: 8
                },
                end: {
                    line: 142,
                    column: 10
                }
            },
            '51': {
                start: {
                    line: 113,
                    column: 16
                },
                end: {
                    line: 113,
                    column: 56
                }
            },
            '52': {
                start: {
                    line: 114,
                    column: 16
                },
                end: {
                    line: 114,
                    column: 77
                }
            },
            '53': {
                start: {
                    line: 115,
                    column: 16
                },
                end: {
                    line: 117,
                    column: 42
                }
            },
            '54': {
                start: {
                    line: 119,
                    column: 16
                },
                end: {
                    line: 119,
                    column: 52
                }
            },
            '55': {
                start: {
                    line: 121,
                    column: 16
                },
                end: {
                    line: 130,
                    column: 18
                }
            },
            '56': {
                start: {
                    line: 122,
                    column: 20
                },
                end: {
                    line: 122,
                    column: 37
                }
            },
            '57': {
                start: {
                    line: 123,
                    column: 20
                },
                end: {
                    line: 123,
                    column: 44
                }
            },
            '58': {
                start: {
                    line: 124,
                    column: 20
                },
                end: {
                    line: 129,
                    column: 21
                }
            },
            '59': {
                start: {
                    line: 125,
                    column: 24
                },
                end: {
                    line: 125,
                    column: 84
                }
            },
            '60': {
                start: {
                    line: 128,
                    column: 24
                },
                end: {
                    line: 128,
                    column: 52
                }
            },
            '61': {
                start: {
                    line: 132,
                    column: 16
                },
                end: {
                    line: 135,
                    column: 19
                }
            },
            '62': {
                start: {
                    line: 133,
                    column: 20
                },
                end: {
                    line: 133,
                    column: 34
                }
            },
            '63': {
                start: {
                    line: 134,
                    column: 20
                },
                end: {
                    line: 134,
                    column: 33
                }
            },
            '64': {
                start: {
                    line: 137,
                    column: 16
                },
                end: {
                    line: 140,
                    column: 19
                }
            },
            '65': {
                start: {
                    line: 138,
                    column: 20
                },
                end: {
                    line: 138,
                    column: 34
                }
            },
            '66': {
                start: {
                    line: 139,
                    column: 20
                },
                end: {
                    line: 139,
                    column: 33
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 50
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 13
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 17
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 64
                    },
                    end: {
                        line: 19,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 77
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                },
                line: 19
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 4
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 20
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 31
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 15
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 40
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 23
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                line: 50
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 55,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 38
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                line: 55
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 60,
                        column: 43
                    },
                    end: {
                        line: 60,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 49
                    },
                    end: {
                        line: 62,
                        column: 13
                    }
                },
                line: 60
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 66,
                        column: 4
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 18
                    },
                    end: {
                        line: 73,
                        column: 5
                    }
                },
                line: 66
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 70,
                        column: 36
                    },
                    end: {
                        line: 70,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 42
                    },
                    end: {
                        line: 72,
                        column: 9
                    }
                },
                line: 70
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 75,
                        column: 4
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 75,
                        column: 29
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                line: 75
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 107,
                        column: 27
                    },
                    end: {
                        line: 107,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 107,
                        column: 47
                    },
                    end: {
                        line: 143,
                        column: 5
                    }
                },
                line: 107
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 110,
                        column: 18
                    },
                    end: {
                        line: 110,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 110,
                        column: 56
                    },
                    end: {
                        line: 141,
                        column: 13
                    }
                },
                line: 110
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 121,
                        column: 25
                    },
                    end: {
                        line: 121,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 121,
                        column: 41
                    },
                    end: {
                        line: 130,
                        column: 17
                    }
                },
                line: 121
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 132,
                        column: 38
                    },
                    end: {
                        line: 132,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 132,
                        column: 54
                    },
                    end: {
                        line: 135,
                        column: 17
                    }
                },
                line: 132
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 137,
                        column: 41
                    },
                    end: {
                        line: 137,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 57
                    },
                    end: {
                        line: 140,
                        column: 17
                    }
                },
                line: 137
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 25,
                        column: 23
                    },
                    end: {
                        line: 25,
                        column: 50
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 25,
                        column: 44
                    },
                    end: {
                        line: 25,
                        column: 46
                    }
                }, {
                    start: {
                        line: 25,
                        column: 49
                    },
                    end: {
                        line: 25,
                        column: 50
                    }
                }],
                line: 25
            },
            '1': {
                loc: {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                }, {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                }],
                line: 34
            },
            '2': {
                loc: {
                    start: {
                        line: 44,
                        column: 8
                    },
                    end: {
                        line: 46,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 44,
                        column: 8
                    },
                    end: {
                        line: 46,
                        column: 9
                    }
                }, {
                    start: {
                        line: 44,
                        column: 8
                    },
                    end: {
                        line: 46,
                        column: 9
                    }
                }],
                line: 44
            },
            '3': {
                loc: {
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 63,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 63,
                        column: 9
                    }
                }, {
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 63,
                        column: 9
                    }
                }],
                line: 56
            },
            '4': {
                loc: {
                    start: {
                        line: 67,
                        column: 8
                    },
                    end: {
                        line: 69,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 67,
                        column: 8
                    },
                    end: {
                        line: 69,
                        column: 9
                    }
                }, {
                    start: {
                        line: 67,
                        column: 8
                    },
                    end: {
                        line: 69,
                        column: 9
                    }
                }],
                line: 67
            },
            '5': {
                loc: {
                    start: {
                        line: 67,
                        column: 12
                    },
                    end: {
                        line: 67,
                        column: 75
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 67,
                        column: 12
                    },
                    end: {
                        line: 67,
                        column: 44
                    }
                }, {
                    start: {
                        line: 67,
                        column: 48
                    },
                    end: {
                        line: 67,
                        column: 75
                    }
                }],
                line: 67
            },
            '6': {
                loc: {
                    start: {
                        line: 84,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 84,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 17
                    }
                }, {
                    start: {
                        line: 84,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 17
                    }
                }],
                line: 84
            },
            '7': {
                loc: {
                    start: {
                        line: 89,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 89,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                }, {
                    start: {
                        line: 89,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                }],
                line: 89
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ProjectStoriesController = function () {
    function ProjectStoriesController(UsersTable, $rootScope, $filter) {
        _classCallCheck(this, ProjectStoriesController);

        cov_29w7p51sxp.f[0]++;
        cov_29w7p51sxp.s[0]++;

        this.$filter = $filter;
        cov_29w7p51sxp.s[1]++;
        this.currentNav = 'user_stories';
        cov_29w7p51sxp.s[2]++;
        this.requestData = {};
        cov_29w7p51sxp.s[3]++;
        this.blocked = 0;
        cov_29w7p51sxp.s[4]++;
        this.roles = [];
    }

    _createClass(ProjectStoriesController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_29w7p51sxp.f[1]++;
            cov_29w7p51sxp.s[5]++;

            this.requestData.ordering = 'delivery';
            cov_29w7p51sxp.s[6]++;
            this.proposal.$load('stories');
            cov_29w7p51sxp.s[7]++;
            this.update();
            cov_29w7p51sxp.s[8]++;
            this.newStory = this.proposal.$stories.$build({ project: this.project.id, proposal: this.proposal.id });
        }
    }, {
        key: 'update',
        value: function update() {
            var _this = this;

            cov_29w7p51sxp.f[2]++;
            cov_29w7p51sxp.s[9]++;

            this.currentDelivery = null;
            cov_29w7p51sxp.s[10]++;
            this.proposal.$stories.$refresh(this.requestData).$then(function (stories) {
                cov_29w7p51sxp.f[3]++;
                cov_29w7p51sxp.s[11]++;

                _this.currentDelivery = _this.getFirstDelivery(stories);
            });
        }
    }, {
        key: 'filterBlocked',
        value: function filterBlocked() {
            cov_29w7p51sxp.f[4]++;
            cov_29w7p51sxp.s[12]++;

            this.blocked = this.blocked === 1 ? (cov_29w7p51sxp.b[0][0]++, '') : (cov_29w7p51sxp.b[0][1]++, 1);
            cov_29w7p51sxp.s[13]++;
            this.requestData.is_blocked = this.blocked;
            cov_29w7p51sxp.s[14]++;
            this.update();
        }
    }, {
        key: 'getFirstDelivery',
        value: function getFirstDelivery(stories) {
            cov_29w7p51sxp.f[5]++;

            var filtered;
            cov_29w7p51sxp.s[15]++;
            filtered = this.$filter('orderBy')(stories, 'delivery');
            cov_29w7p51sxp.s[16]++;
            filtered = this.$filter('groupBy')(filtered, 'delivery');
            cov_29w7p51sxp.s[17]++;
            if (Object.keys(filtered).length > 0) {
                cov_29w7p51sxp.b[1][0]++;
                cov_29w7p51sxp.s[18]++;

                return Object.keys(filtered)[0];
            } else {
                cov_29w7p51sxp.b[1][1]++;
            }
            cov_29w7p51sxp.s[19]++;
            return null;
        }
    }, {
        key: 'getRoles',
        value: function getRoles() {
            cov_29w7p51sxp.f[6]++;

            var filtered;
            cov_29w7p51sxp.s[20]++;
            filtered = this.$filter('orderBy')(this.proposal.$stories, 'role');
            cov_29w7p51sxp.s[21]++;
            filtered = this.$filter('groupBy')(filtered, 'role');
            cov_29w7p51sxp.s[22]++;
            if (Object.keys(filtered).length > 0) {
                cov_29w7p51sxp.b[2][0]++;
                cov_29w7p51sxp.s[23]++;

                return this.roles.concat(Object.keys(filtered));
            } else {
                cov_29w7p51sxp.b[2][1]++;
            }
            cov_29w7p51sxp.s[24]++;
            return [];
        }
    }, {
        key: 'changeBlock',
        value: function changeBlock(story) {
            cov_29w7p51sxp.f[7]++;
            cov_29w7p51sxp.s[25]++;

            story.isBlocked = !story.isBlocked;
            cov_29w7p51sxp.s[26]++;
            this.changeAttribute(story, 'isBlocked');
        }
    }, {
        key: 'changeAttribute',
        value: function changeAttribute(story, attribute) {
            var _this2 = this;

            cov_29w7p51sxp.f[8]++;
            cov_29w7p51sxp.s[27]++;

            if (story.version === null) {
                cov_29w7p51sxp.b[3][0]++;
                cov_29w7p51sxp.s[28]++;

                story.$save([attribute]);
            } else {
                cov_29w7p51sxp.b[3][1]++;
                cov_29w7p51sxp.s[29]++;

                story.$save([attribute]).$then(function () {
                    cov_29w7p51sxp.f[9]++;
                    cov_29w7p51sxp.s[30]++;

                    _this2.proposal.$stories.$refresh();
                });
            }
        }
    }, {
        key: 'createStory',
        value: function createStory() {
            var _this3 = this;

            cov_29w7p51sxp.f[10]++;
            cov_29w7p51sxp.s[31]++;

            if ((cov_29w7p51sxp.b[5][0]++, this.newStory.role === undefined) || (cov_29w7p51sxp.b[5][1]++, this.newStory.role === null)) {
                cov_29w7p51sxp.b[4][0]++;
                cov_29w7p51sxp.s[32]++;

                this.newStory.role = this.searchText;
            } else {
                cov_29w7p51sxp.b[4][1]++;
            }
            cov_29w7p51sxp.s[33]++;
            this.newStory.$save().$then(function () {
                cov_29w7p51sxp.f[11]++;
                cov_29w7p51sxp.s[34]++;

                _this3.newStory = _this3.proposal.$stories.$build({ project: _this3.project.id, proposal: _this3.proposal.id, delivery: 1 });
            });
        }
    }, {
        key: 'querySearch',
        value: function querySearch(searchText) {
            cov_29w7p51sxp.f[12]++;

            var roles = (cov_29w7p51sxp.s[35]++, this.getRoles());
            var items = (cov_29w7p51sxp.s[36]++, []);
            var search = (cov_29w7p51sxp.s[37]++, searchText.toLowerCase());
            cov_29w7p51sxp.s[38]++;
            for (var i = 0; i < roles.length; i++) {
                cov_29w7p51sxp.s[39]++;

                console.log(roles[i]);
                var contained = (cov_29w7p51sxp.s[40]++, true);
                cov_29w7p51sxp.s[41]++;
                for (var j = 0; j < searchText.length; j++) {
                    cov_29w7p51sxp.s[42]++;

                    console.log(searchText[j]);
                    cov_29w7p51sxp.s[43]++;
                    if (roles[i].toLowerCase().indexOf(search[j]) === -1) {
                        cov_29w7p51sxp.b[6][0]++;
                        cov_29w7p51sxp.s[44]++;

                        contained = false;
                        cov_29w7p51sxp.s[45]++;
                        break;
                    } else {
                        cov_29w7p51sxp.b[6][1]++;
                    }
                }
                cov_29w7p51sxp.s[46]++;
                if (contained) {
                    cov_29w7p51sxp.b[7][0]++;
                    cov_29w7p51sxp.s[47]++;

                    items.push(roles[i]);
                } else {
                    cov_29w7p51sxp.b[7][1]++;
                }
            }
            cov_29w7p51sxp.s[48]++;
            return items;
        }
    }]);

    return ProjectStoriesController;
}();

cov_29w7p51sxp.s[49]++;


angular.module('app').component('projectStories', {
    templateUrl: 'app/components/projectStories/projectStories.html',
    controller: ProjectStoriesController,
    bindings: {
        project: '<',
        proposal: '<'
    }
}).directive('autosize', function ($document) {
    cov_29w7p51sxp.f[13]++;
    cov_29w7p51sxp.s[50]++;

    return {
        require: 'ngModel',
        link: function link(scope, element, attrs, ctrl) {
            cov_29w7p51sxp.f[14]++;

            var span, resize;

            cov_29w7p51sxp.s[51]++;
            span = angular.element('<span></span>');
            cov_29w7p51sxp.s[52]++;
            span[0].style.cssText = getComputedStyle(element[0]).cssText;
            cov_29w7p51sxp.s[53]++;
            span.css('display', 'none').css('visibility', 'hidden').css('width', 'auto');

            cov_29w7p51sxp.s[54]++;
            $document.find('body').append(span);

            cov_29w7p51sxp.s[55]++;
            resize = function resize(value) {
                cov_29w7p51sxp.f[15]++;
                cov_29w7p51sxp.s[56]++;

                span.text(value);
                cov_29w7p51sxp.s[57]++;
                span.css('display', '');
                cov_29w7p51sxp.s[58]++;
                try {
                    cov_29w7p51sxp.s[59]++;

                    element.css('width', span.prop('offsetWidth') + 2 + 'px');
                } finally {
                    cov_29w7p51sxp.s[60]++;

                    span.css('display', 'none');
                }
            };

            cov_29w7p51sxp.s[61]++;
            ctrl.$parsers.unshift(function (value) {
                cov_29w7p51sxp.f[16]++;
                cov_29w7p51sxp.s[62]++;

                resize(value);
                cov_29w7p51sxp.s[63]++;
                return value;
            });

            cov_29w7p51sxp.s[64]++;
            ctrl.$formatters.unshift(function (value) {
                cov_29w7p51sxp.f[17]++;
                cov_29w7p51sxp.s[65]++;

                resize(value);
                cov_29w7p51sxp.s[66]++;
                return value;
            });
        }
    };
});