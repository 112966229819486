'use strict';

var cov_23gvvg89gs = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/userProfile/userProfile.js',
        hash = 'cc0ec3f0d0f8c3aa61eb183430b2d193bf305842',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/userProfile/userProfile.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 10
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 16
                },
                end: {
                    line: 5,
                    column: 27
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 29
                },
                end: {
                    line: 10,
                    column: 49
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 27
                },
                end: {
                    line: 11,
                    column: 54
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 12,
                    column: 36
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 20
                },
                end: {
                    line: 15,
                    column: 93
                }
            },
            '7': {
                start: {
                    line: 15,
                    column: 69
                },
                end: {
                    line: 15,
                    column: 91
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 37
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            '10': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 34
                }
            },
            '11': {
                start: {
                    line: 30,
                    column: 18
                },
                end: {
                    line: 33,
                    column: 9
                }
            },
            '12': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 41
                }
            },
            '14': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 96
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 73
                },
                end: {
                    line: 37,
                    column: 94
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 30
                }
            },
            '17': {
                start: {
                    line: 42,
                    column: 0
                },
                end: {
                    line: 67,
                    column: 7
                }
            },
            '18': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 10
                }
            },
            '19': {
                start: {
                    line: 53,
                    column: 12
                },
                end: {
                    line: 55,
                    column: 13
                }
            },
            '20': {
                start: {
                    line: 54,
                    column: 16
                },
                end: {
                    line: 54,
                    column: 29
                }
            },
            '21': {
                start: {
                    line: 57,
                    column: 27
                },
                end: {
                    line: 57,
                    column: 29
                }
            },
            '22': {
                start: {
                    line: 59,
                    column: 12
                },
                end: {
                    line: 63,
                    column: 15
                }
            },
            '23': {
                start: {
                    line: 60,
                    column: 16
                },
                end: {
                    line: 62,
                    column: 17
                }
            },
            '24': {
                start: {
                    line: 61,
                    column: 20
                },
                end: {
                    line: 61,
                    column: 39
                }
            },
            '25': {
                start: {
                    line: 65,
                    column: 12
                },
                end: {
                    line: 65,
                    column: 28
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 4,
                        column: 20
                    },
                    end: {
                        line: 4,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 4,
                        column: 66
                    },
                    end: {
                        line: 6,
                        column: 13
                    }
                },
                line: 4
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 7,
                        column: 23
                    },
                    end: {
                        line: 7,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 34
                    },
                    end: {
                        line: 8,
                        column: 13
                    }
                },
                line: 7
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 9,
                        column: 26
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                line: 9
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 14,
                        column: 44
                    },
                    end: {
                        line: 14,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 50
                    },
                    end: {
                        line: 16,
                        column: 17
                    }
                },
                line: 14
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 15,
                        column: 58
                    },
                    end: {
                        line: 15,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 69
                    },
                    end: {
                        line: 15,
                        column: 91
                    }
                },
                line: 15
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 14
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 22
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 24,
                        column: 35
                    },
                    end: {
                        line: 24,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 46
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 24
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 29,
                        column: 4
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 38
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 29
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 37,
                        column: 63
                    },
                    end: {
                        line: 37,
                        column: 64
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 73
                    },
                    end: {
                        line: 37,
                        column: 94
                    }
                },
                line: 37
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 51,
                        column: 23
                    },
                    end: {
                        line: 51,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 35
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                line: 51
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 52,
                        column: 15
                    },
                    end: {
                        line: 52,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 36
                    },
                    end: {
                        line: 66,
                        column: 9
                    }
                },
                line: 52
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 59,
                        column: 35
                    },
                    end: {
                        line: 59,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 51
                    },
                    end: {
                        line: 63,
                        column: 13
                    }
                },
                line: 59
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                }, {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                }],
                line: 34
            },
            '1': {
                loc: {
                    start: {
                        line: 34,
                        column: 12
                    },
                    end: {
                        line: 34,
                        column: 49
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 34,
                        column: 12
                    },
                    end: {
                        line: 34,
                        column: 23
                    }
                }, {
                    start: {
                        line: 34,
                        column: 27
                    },
                    end: {
                        line: 34,
                        column: 49
                    }
                }],
                line: 34
            },
            '2': {
                loc: {
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 55,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 55,
                        column: 13
                    }
                }, {
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 55,
                        column: 13
                    }
                }],
                line: 53
            },
            '3': {
                loc: {
                    start: {
                        line: 53,
                        column: 16
                    },
                    end: {
                        line: 53,
                        column: 32
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 53,
                        column: 16
                    },
                    end: {
                        line: 53,
                        column: 19
                    }
                }, {
                    start: {
                        line: 53,
                        column: 23
                    },
                    end: {
                        line: 53,
                        column: 32
                    }
                }],
                line: 53
            },
            '4': {
                loc: {
                    start: {
                        line: 60,
                        column: 16
                    },
                    end: {
                        line: 62,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 60,
                        column: 16
                    },
                    end: {
                        line: 62,
                        column: 17
                    }
                }, {
                    start: {
                        line: 60,
                        column: 16
                    },
                    end: {
                        line: 62,
                        column: 17
                    }
                }],
                line: 60
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserProfileController = function () {
    function UserProfileController() {
        var _this = this;

        _classCallCheck(this, UserProfileController);

        cov_23gvvg89gs.f[0]++;
        cov_23gvvg89gs.s[0]++;

        this.sortableListeners = {
            accept: function accept(sourceItemHandleScope, destSortableScope) {
                cov_23gvvg89gs.f[1]++;
                cov_23gvvg89gs.s[1]++;

                return true;
            },
            itemMoved: function itemMoved(event) {
                cov_23gvvg89gs.f[2]++;
            },
            orderChanged: function orderChanged(event) {
                cov_23gvvg89gs.f[3]++;

                var newPos = (cov_23gvvg89gs.s[2]++, event.dest.index + 1);
                var task = (cov_23gvvg89gs.s[3]++, event.source.itemScope.task);
                cov_23gvvg89gs.s[4]++;
                task.order = newPos;

                cov_23gvvg89gs.s[5]++;
                task.$save(['order']).$then(function () {
                    cov_23gvvg89gs.f[4]++;
                    cov_23gvvg89gs.s[6]++;

                    _this.user.$openTasks.$refresh().$then(function (tasks) {
                        cov_23gvvg89gs.f[5]++;
                        cov_23gvvg89gs.s[7]++;
                        return tasks.$load('project');
                    });
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };
    }

    _createClass(UserProfileController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_23gvvg89gs.f[6]++;
            cov_23gvvg89gs.s[8]++;

            this.user.$load('openTasks');
            cov_23gvvg89gs.s[9]++;
            this.user.$openTasks.$then(function (tasks) {
                cov_23gvvg89gs.f[7]++;
                cov_23gvvg89gs.s[10]++;

                tasks.$load('project');
            });
        }
    }, {
        key: 'createTask',
        value: function createTask(taskName, taskProject) {
            cov_23gvvg89gs.f[8]++;

            var obj = (cov_23gvvg89gs.s[11]++, {
                name: taskName,
                user: this.user.id
            });
            cov_23gvvg89gs.s[12]++;
            if ((cov_23gvvg89gs.b[1][0]++, taskProject) && (cov_23gvvg89gs.b[1][1]++, taskProject !== 'None')) {
                cov_23gvvg89gs.b[0][0]++;
                cov_23gvvg89gs.s[13]++;

                obj['project'] = taskProject;
            } else {
                cov_23gvvg89gs.b[0][1]++;
            }
            cov_23gvvg89gs.s[14]++;
            this.newTask = this.user.$openTasks.$create(obj).$then(function (task) {
                cov_23gvvg89gs.f[9]++;
                cov_23gvvg89gs.s[15]++;
                return task.$load('project');
            });
            cov_23gvvg89gs.s[16]++;
            this.newTaskName = '';
        }
    }]);

    return UserProfileController;
}();

cov_23gvvg89gs.s[17]++;


angular.module('app').component('userProfile', {
    templateUrl: 'app/components/userProfile/userProfile.html',
    controller: UserProfileController,
    bindings: {
        user: '<'
    }
}).filter('project', function () {
    cov_23gvvg89gs.f[10]++;
    cov_23gvvg89gs.s[18]++;

    return function (items, id) {
        cov_23gvvg89gs.f[11]++;
        cov_23gvvg89gs.s[19]++;

        if ((cov_23gvvg89gs.b[3][0]++, !id) || (cov_23gvvg89gs.b[3][1]++, id == '*')) {
            cov_23gvvg89gs.b[2][0]++;
            cov_23gvvg89gs.s[20]++;

            return items;
        } else {
            cov_23gvvg89gs.b[2][1]++;
        }

        var filtered = (cov_23gvvg89gs.s[21]++, []);

        cov_23gvvg89gs.s[22]++;
        angular.forEach(items, function (item) {
            cov_23gvvg89gs.f[12]++;
            cov_23gvvg89gs.s[23]++;

            if (item.project == id) {
                cov_23gvvg89gs.b[4][0]++;
                cov_23gvvg89gs.s[24]++;

                filtered.push(item);
            } else {
                cov_23gvvg89gs.b[4][1]++;
            }
        });

        cov_23gvvg89gs.s[25]++;
        return filtered;
    };
});
;