'use strict';

var cov_1shis9lnzz = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/taskHeadline/taskHeadline.js',
        hash = '4511bcb2ecdff30d3b7e92f456ae6ad873244547',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/taskHeadline/taskHeadline.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 62
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 74
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 65
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 16
                },
                end: {
                    line: 10,
                    column: 37
                }
            },
            '7': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 37
                }
            },
            '10': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 84
                }
            },
            '11': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 31
                }
            },
            '12': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 74
                }
            },
            '14': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 32
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 9
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 48
                }
            },
            '17': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 11
                }
            },
            '18': {
                start: {
                    line: 41,
                    column: 12
                },
                end: {
                    line: 41,
                    column: 74
                }
            },
            '19': {
                start: {
                    line: 42,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 32
                }
            },
            '20': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 29
                }
            },
            '21': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 45
                }
            },
            '22': {
                start: {
                    line: 52,
                    column: 0
                },
                end: {
                    line: 60,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 101
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 35
                    },
                    end: {
                        line: 8,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 52
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 35
                    },
                    end: {
                        line: 14,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 52
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 14
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 16
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 25
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 29,
                        column: 4
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 19
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 29
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 30,
                        column: 47
                    },
                    end: {
                        line: 30,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 53
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                line: 30
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 31
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 36
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 40,
                        column: 46
                    },
                    end: {
                        line: 40,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 52
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                },
                line: 40
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 19
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 46
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }, {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }],
                line: 9
            },
            '1': {
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 15
            },
            '2': {
                loc: {
                    start: {
                        line: 36,
                        column: 16
                    },
                    end: {
                        line: 36,
                        column: 29
                    }
                },
                type: 'default-arg',
                locations: [{
                    start: {
                        line: 36,
                        column: 24
                    },
                    end: {
                        line: 36,
                        column: 29
                    }
                }],
                line: 36
            },
            '3': {
                loc: {
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                }, {
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                }],
                line: 37
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TaskHeadlineController = function () {
    function TaskHeadlineController(StartWorkingTable, StopWorkingService, $rootScope, $scope, NewExecutionModalService) {
        var _this = this;

        _classCallCheck(this, TaskHeadlineController);

        cov_1shis9lnzz.f[0]++;
        cov_1shis9lnzz.s[0]++;

        this.startWorkingService = StartWorkingTable.$build();
        cov_1shis9lnzz.s[1]++;
        this.stopWorkingService = StopWorkingService.$build({ close: '0' });
        cov_1shis9lnzz.s[2]++;
        this.$rootScope = $rootScope;
        cov_1shis9lnzz.s[3]++;
        this.NewExecutionModalService = NewExecutionModalService;

        cov_1shis9lnzz.s[4]++;
        $scope.$on('task-stopped', function (event, args) {
            cov_1shis9lnzz.f[1]++;
            cov_1shis9lnzz.s[5]++;

            if (args.task.id == _this.task.id) {
                cov_1shis9lnzz.b[0][0]++;
                cov_1shis9lnzz.s[6]++;

                _this.task.$refresh();
            } else {
                cov_1shis9lnzz.b[0][1]++;
            }
        });

        cov_1shis9lnzz.s[7]++;
        $scope.$on('task-started', function (event, args) {
            cov_1shis9lnzz.f[2]++;
            cov_1shis9lnzz.s[8]++;

            if (_this.task.state == 'EXECUTING') {
                cov_1shis9lnzz.b[1][0]++;
                cov_1shis9lnzz.s[9]++;

                _this.task.$refresh();
            } else {
                cov_1shis9lnzz.b[1][1]++;
            }
        });
    }

    _createClass(TaskHeadlineController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1shis9lnzz.f[3]++;
            cov_1shis9lnzz.s[10]++;

            this.startWorkingService.task = this.stopWorkingService.task = this.task.id;
        }
    }, {
        key: 'closeTask',
        value: function closeTask() {
            cov_1shis9lnzz.f[4]++;
            cov_1shis9lnzz.s[11]++;

            this.stopWorking(true);
        }
    }, {
        key: 'startWorking',
        value: function startWorking() {
            var _this2 = this;

            cov_1shis9lnzz.f[5]++;
            cov_1shis9lnzz.s[12]++;

            this.startWorkingService.$save().$then(function () {
                cov_1shis9lnzz.f[6]++;
                cov_1shis9lnzz.s[13]++;

                _this2.$rootScope.$broadcast('task-started', { task: _this2.task });
                cov_1shis9lnzz.s[14]++;
                _this2._refreshData();
            });
        }
    }, {
        key: 'stopWorking',
        value: function stopWorking() {
            var _this3 = this;

            var close = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_1shis9lnzz.b[2][0]++, false);
            cov_1shis9lnzz.f[7]++;
            cov_1shis9lnzz.s[15]++;

            if (close) {
                cov_1shis9lnzz.b[3][0]++;
                cov_1shis9lnzz.s[16]++;

                this.stopWorkingService.close = '1';
            } else {
                cov_1shis9lnzz.b[3][1]++;
            }
            cov_1shis9lnzz.s[17]++;
            this.stopWorkingService.$save().$then(function () {
                cov_1shis9lnzz.f[8]++;
                cov_1shis9lnzz.s[18]++;

                _this3.$rootScope.$broadcast('task-stopped', { task: _this3.task });
                cov_1shis9lnzz.s[19]++;
                _this3._refreshData();
            });
        }
    }, {
        key: '_refreshData',
        value: function _refreshData() {
            cov_1shis9lnzz.f[9]++;
            cov_1shis9lnzz.s[20]++;

            this.task.$refresh();
            cov_1shis9lnzz.s[21]++;
            this.$rootScope.refreshDynamicData();
        }
    }]);

    return TaskHeadlineController;
}();

cov_1shis9lnzz.s[22]++;


angular.module('app').component('taskHeadline', {
    templateUrl: 'app/components/taskHeadline/taskHeadline.html',
    controller: TaskHeadlineController,
    bindings: {
        task: '<'
    }
});