'use strict';

var cov_pb7oh44k5 = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.proposals.factory.js',
        hash = 'd95f835ce7e3de4ffa2d334d09a3ca90059a8b04',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/js/ORM/tables.proposals.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 33,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 11
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 33,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 38
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                line: 9
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_pb7oh44k5.s[0]++;
(function () {
    'use strict';

    cov_pb7oh44k5.f[0]++;
    cov_pb7oh44k5.s[1]++;
    angular.module('app').factory('ProposalsTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_pb7oh44k5.f[1]++;
        cov_pb7oh44k5.s[2]++;


        return restmod.model('/proposals').mix('MixinContainable', {
            $extend: {
                Collection: {
                    $limit: 1000
                }
            },
            $config: {
                contains: {
                    project: {
                        type: 'belongsTo',
                        table: 'ProjectsTable',
                        foreignKey: 'project'
                    },
                    stories: {
                        type: 'hasMany',
                        table: 'StoriesTable',
                        foreignKey: 'proposal'
                    }
                }
            }
        });
    }
})();