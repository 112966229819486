'use strict';

var cov_mjp86hmws = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/newProjectReportModal/newProjectReportModal.js',
        hash = '79a52e20122ebbc5fccc5287c676dd75776e5b26',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/newProjectReportModal/newProjectReportModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 38,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 79
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 22
                },
                end: {
                    line: 10,
                    column: 26
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 73
                }
            },
            '6': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 108
                }
            },
            '7': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 31
                }
            },
            '9': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 10
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 47
                }
            },
            '11': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 45
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 15
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 39
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 38,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewProjectReportModalService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 54
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 23
                    },
                    end: {
                        line: 11,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 35
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: 'NewProjectReportModalController',
                decl: {
                    start: {
                        line: 22,
                        column: 13
                    },
                    end: {
                        line: 22,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 116
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 29
                    },
                    end: {
                        line: 26,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 41
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 37
                    },
                    end: {
                        line: 30,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 49
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 33,
                        column: 43
                    },
                    end: {
                        line: 33,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 61
                    },
                    end: {
                        line: 35,
                        column: 13
                    }
                },
                line: 33
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_mjp86hmws.s[0]++;
(function () {
    'use strict';

    cov_mjp86hmws.f[0]++;
    cov_mjp86hmws.s[1]++;
    angular.module('app').service('NewProjectReportModalService', NewProjectReportModalService);

    /* @ngInject */
    function NewProjectReportModalService($mdDialog) {
        cov_mjp86hmws.f[1]++;

        var Service = (cov_mjp86hmws.s[2]++, this);
        cov_mjp86hmws.s[3]++;
        this.display = function () {
            cov_mjp86hmws.f[2]++;
            cov_mjp86hmws.s[4]++;

            return $mdDialog.show({
                templateUrl: 'app/components/newProjectReportModal/newProjectReportModal.html',
                controller: NewProjectReportModalController,
                clickOutsideToClose: true,
                escapeToClose: true
            });
        };
    }

    /* @ngInject */
    function NewProjectReportModalController($scope, $mdDialog, ProjectReportsTable, NewProjectReportModalService) {
        cov_mjp86hmws.f[3]++;
        cov_mjp86hmws.s[5]++;

        this.NewProjectReportModalService = NewProjectReportModalService;
        cov_mjp86hmws.s[6]++;
        $scope.newReport = ProjectReportsTable.$build({ project: this.NewProjectReportModalService.project });

        cov_mjp86hmws.s[7]++;
        $scope.closeDialog = function () {
            cov_mjp86hmws.f[4]++;
            cov_mjp86hmws.s[8]++;

            $mdDialog.cancel();
        };

        cov_mjp86hmws.s[9]++;
        $scope.createProjectReport = function () {
            cov_mjp86hmws.f[5]++;
            cov_mjp86hmws.s[10]++;

            $scope.newReport.start.setHours(2);
            cov_mjp86hmws.s[11]++;
            $scope.newReport.end.setHours(2);
            cov_mjp86hmws.s[12]++;
            $scope.newReport.$save().$then(function (report) {
                cov_mjp86hmws.f[6]++;
                cov_mjp86hmws.s[13]++;

                $mdDialog.hide(report);
            });
        };
    }
})(angular);