'use strict';

var cov_1fbkat2hkf = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/recurrentList/recurrentList.js',
        hash = 'be1ce9bb28d85b9d873afa07f775f4cce47a194f',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/recurrentList/recurrentList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 59
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 9,
                    column: 44
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 55
                }
            },
            '5': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 23,
                    column: 0
                },
                end: {
                    line: 31,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 40
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 32
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 8,
                        column: 50
                    },
                    end: {
                        line: 8,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 63
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                line: 8
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 18
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 14
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 14
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 18
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RecurrentListController = function () {
    function RecurrentListController(RecurrentModalService) {
        _classCallCheck(this, RecurrentListController);

        cov_1fbkat2hkf.f[0]++;
        cov_1fbkat2hkf.s[0]++;

        this.RecurrentModalService = RecurrentModalService;
    }

    _createClass(RecurrentListController, [{
        key: 'displayNewRecurrentModal',
        value: function displayNewRecurrentModal() {
            var _this = this;

            cov_1fbkat2hkf.f[1]++;
            cov_1fbkat2hkf.s[1]++;

            this.RecurrentModalService.display().then(function (recurrent) {
                cov_1fbkat2hkf.f[2]++;
                cov_1fbkat2hkf.s[2]++;

                _this.recurrents.$add(recurrent);
                cov_1fbkat2hkf.s[3]++;
                _this.refreshList();
            });
        }
    }, {
        key: 'refreshList',
        value: function refreshList() {
            cov_1fbkat2hkf.f[3]++;
            cov_1fbkat2hkf.s[4]++;

            this.recurrents.$load('project').$load('user');
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            cov_1fbkat2hkf.f[4]++;
            cov_1fbkat2hkf.s[5]++;

            this.refreshList();
        }
    }]);

    return RecurrentListController;
}();

cov_1fbkat2hkf.s[6]++;


angular.module('app').component('recurrentList', {
    templateUrl: 'app/components/recurrentList/recurrentList.html',
    controller: RecurrentListController,
    bindings: {
        recurrents: '<'
    }
});