'use strict';

var cov_wkjbkyrxw = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/executionPreview/executionPreview.js',
        hash = '7e2a60c69138d6b14e914080e30c27bb855444e5',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/executionPreview/executionPreview.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 0
                },
                end: {
                    line: 12,
                    column: 3
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ExecutionPreviewController = function ExecutionPreviewController() {
    _classCallCheck(this, ExecutionPreviewController);
};

cov_wkjbkyrxw.s[0]++;


angular.module('app').component('executionPreview', {
    templateUrl: 'app/components/executionPreview/executionPreview.html',
    controller: ExecutionPreviewController,
    bindings: {
        execution: '<'
    }
});