'use strict';

var cov_bf9pjdcbk = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/teamWorkingStatus/teamWorkingStatus.js',
        hash = '4ef29bb76cf25b613caf0228401b69418bb00427',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/teamWorkingStatus/teamWorkingStatus.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 36
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 32
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 52
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 15
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 8
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TeamWorkingStatusController = function () {
    function TeamWorkingStatusController(UsersTable, $interval, $rootScope) {
        _classCallCheck(this, TeamWorkingStatusController);

        cov_bf9pjdcbk.f[0]++;
        cov_bf9pjdcbk.s[0]++;

        this.team = UsersTable.$all;
        cov_bf9pjdcbk.s[1]++;
        this.me = $rootScope.me;
        cov_bf9pjdcbk.s[2]++;
        this.$rootScope = $rootScope;
    }

    _createClass(TeamWorkingStatusController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_bf9pjdcbk.f[1]++;
        }
    }]);

    return TeamWorkingStatusController;
}();

cov_bf9pjdcbk.s[3]++;


angular.module('app').component('teamWorkingStatus', {
    templateUrl: 'app/components/teamWorkingStatus/teamWorkingStatus.html',
    controller: TeamWorkingStatusController
});