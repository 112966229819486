'use strict';

var cov_1itzf79zk3 = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/components/paginator/paginator.js',
        hash = 'de72c22662a66fb3b4fafc2d1b313a8a7494e980',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/components/paginator/paginator.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 34
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 40
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 9
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 9,
                    column: 49
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 23
                },
                end: {
                    line: 14,
                    column: 63
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 31
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 38
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 21,
                    column: 20
                },
                end: {
                    line: 21,
                    column: 45
                }
            },
            '9': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 27
                }
            },
            '10': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 37
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 43
                }
            },
            '12': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 58
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 0
                },
                end: {
                    line: 42,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 41
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 13
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 4
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 23
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 19
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 18
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 19
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 25
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 8,
                        column: 8
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 8,
                        column: 8
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                }, {
                    start: {
                        line: 8,
                        column: 8
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                }],
                line: 8
            },
            '1': {
                loc: {
                    start: {
                        line: 8,
                        column: 11
                    },
                    end: {
                        line: 8,
                        column: 64
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 8,
                        column: 11
                    },
                    end: {
                        line: 8,
                        column: 33
                    }
                }, {
                    start: {
                        line: 8,
                        column: 37
                    },
                    end: {
                        line: 8,
                        column: 64
                    }
                }],
                line: 8
            },
            '2': {
                loc: {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 38
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 38
                    }
                }, {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 38
                    }
                }],
                line: 19
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaginatorController = function () {
    function PaginatorController($stateParams, $location) {
        _classCallCheck(this, PaginatorController);

        cov_1itzf79zk3.f[0]++;
        cov_1itzf79zk3.s[0]++;

        this.$location = $location;
        cov_1itzf79zk3.s[1]++;
        this.$stateParams = $stateParams;
    }

    _createClass(PaginatorController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1itzf79zk3.f[1]++;
            cov_1itzf79zk3.s[2]++;

            if ((cov_1itzf79zk3.b[1][0]++, this.$stateParams.page) && (cov_1itzf79zk3.b[1][1]++, this.$stateParams.page != 1)) {
                cov_1itzf79zk3.b[0][0]++;
                cov_1itzf79zk3.s[3]++;

                this.goToPage(this.$stateParams.page);
            } else {
                cov_1itzf79zk3.b[0][1]++;
            }
        }
    }, {
        key: 'goToPreviousPage',
        value: function goToPreviousPage() {
            cov_1itzf79zk3.f[2]++;

            var previous = (cov_1itzf79zk3.s[4]++, Math.max(1, this.collection.$page - 1));
            cov_1itzf79zk3.s[5]++;
            this.goToPage(previous);
        }
    }, {
        key: 'goToNextPage',
        value: function goToNextPage() {
            cov_1itzf79zk3.f[3]++;
            cov_1itzf79zk3.s[6]++;

            if (!this.collection.$page) {
                    cov_1itzf79zk3.b[2][0]++;
                    cov_1itzf79zk3.s[7]++;

                    this.collection.$page = 0;
                } else {
                cov_1itzf79zk3.b[2][1]++;
            }var next = (cov_1itzf79zk3.s[8]++, this.collection.$page + 1);
            cov_1itzf79zk3.s[9]++;
            this.goToPage(next);
        }
    }, {
        key: 'goToPage',
        value: function goToPage(page) {
            cov_1itzf79zk3.f[4]++;
            cov_1itzf79zk3.s[10]++;

            this.collection.$page = page;

            cov_1itzf79zk3.s[11]++;
            this.$location.search('page', page);

            cov_1itzf79zk3.s[12]++;
            this.collection.$refresh(this.collection.$params);
        }
    }]);

    return PaginatorController;
}();

cov_1itzf79zk3.s[13]++;


angular.module('app').component('paginator', {
    templateUrl: 'app/components/paginator/paginator.html',
    controller: PaginatorController,
    bindings: {
        collection: '<'
    }
});