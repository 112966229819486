'use strict';

var cov_2e5lewc3hb = function () {
    var path = '/home/lobo/repos/taskatone-app/src/app/shared/fab/fab.js',
        hash = '3c4567d972592b4ffbf32221ced6cb34415bc8fc',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/taskatone-app/src/app/shared/fab/fab.js',
        statementMap: {
            '0': {
                start: {
                    line: 7,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            }
        },
        branchMap: {},
        s: {
            '0': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FabController = function FabController() {
    _classCallCheck(this, FabController);

    cov_2e5lewc3hb.f[0]++;
};

cov_2e5lewc3hb.s[0]++;


angular.module('app').component('fab', {
    templateUrl: 'app/shared/fab/fab.html',
    controller: FabController,
    bindings: {
        onAction: '&',
        tooltip: '@',
        icon: '@'
    }
});